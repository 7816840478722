import React from 'react';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import CommonHeaderChat from './CommonHeaderChat';
import Search from '../../../components/Search';
import HeaderMenu from '../../../components/Header';
import CommonHeaderRight from './CommonHeaderRight';

const DashboardHeader = () => {
	return (
		<Header>
			<HeaderLeft>
				<HeaderMenu />
			</HeaderLeft>
			<CommonHeaderRight afterChildren={<CommonHeaderChat />} />
			 
		</Header>
	);
};
export default DashboardHeader;
