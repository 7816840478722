import React, { useEffect, useState, FC } from 'react';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { Calendar as DatePicker } from 'react-date-range';
import classNames from 'classnames';
import Button from './bootstrap/Button';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from './bootstrap/Card';
import PaginationButtons, {
	dataPagination,
	PER_COUNT,
} from './PaginationButtons';
import useSortableData from '../hooks/useSortableData';
import Icon from './icon/Icon';
import useSelectTable from '../hooks/useSelectTable';
import useDarkMode from '../hooks/useDarkMode';
import useTourStep from '../hooks/useTourStep';
import axios from 'axios';
import moment from 'moment';

const AOI = () =>{
	/**
	 * For Tour
	 */
	useTourStep(6);

	const { themeStatus, darkModeStatus } = useDarkMode();
	const [tableItems, setTableItems] = useState<any>([])

	const [currentPage, setCurrentPage] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(PER_COUNT['10']);

	const { items, requestSort, getClassNamesFor } = useSortableData(tableItems);
	const onCurrentPageItems = dataPagination(items, currentPage, perPage);
	const { selectTable, SelectAllCheck } = useSelectTable(onCurrentPageItems);	
	const [datenow, setDatenow] = useState(moment().format('YYYYMMDD'));

	useEffect(() => {
		axios
			.get(`https://i-motion.dephub.go.id/inplatform/dephubmarine/data/${datenow}/alerts/reportsAoi/latest/index.json?fs`)
			.then(response => {
			setTableItems(response.data.report)
			})
			.catch(error => {
			console.log(error);
			});

	}, []);


	return (		
		<Card stretch data-tour='list'>
			<CardHeader>
				<CardLabel icon='List' iconColor='info'>
					<CardTitle>
						AOI{' '}
						<small className='ms-2'>
							Item:{' '}
							{selectTable.values.selectedList.length
								? `${selectTable.values.selectedList.length} / `
								: null}
							{tableItems.length}
						</small>
					</CardTitle>
				</CardLabel>
				<CardActions>
					<Button
						color='info'
						icon='CloudDownload'
						isLight
						tag='a'
						to='/somefile.txt'
						target='_blank'
						download>
						Export
					</Button>							
					{/* <Dropdown className='d-inline'>
						<DropdownToggle hasIcon={false}>
							<Button color={themeStatus} icon='MoreHoriz' />
						</DropdownToggle>
						<DropdownMenu isAlignmentEnd>
							<DropdownItem>
								<Button icon='Edit'>Edit</Button>
							</DropdownItem>
							<DropdownItem>
								<Button icon='Delete'>Delete</Button>
							</DropdownItem>
						</DropdownMenu>
					</Dropdown> */}
				</CardActions>
			</CardHeader>
			<CardBody className='table-responsive' isScrollable>
				<table className='table table-modern table-hover'>
					<thead>
						<tr>
							{/* <th scope='col'>{SelectAllCheck}</th> */}							
							<th scope='col'>Timestamp</th>
							<th scope='col'>AOI</th>
							<th scope='col'>Port</th>
							<th scope='col'>MMSI</th>
							<th scope='col'>Callsign</th>
							<th scope='col'>Name</th>
							<th scope='col'>Ship Type</th>
							<th scope='col'>Status</th>
							<td />
						</tr>
					</thead>
					<tbody>
						{dataPagination(items, currentPage, perPage).map((item) => (
							<tr key={item[5]}>									
								<td>{moment.unix(item[4]).format("MM/DD/YYYY HH:mm:ss")}</td>												
								<td>{item[3]}</td>																					
								<td>{item[9]}</td>								
								<td>{item[1]}</td>							
								<td>{item[5]}</td>						
								<td>{item[0]}</td>
								<td>{item[6]}</td>
								<td>{item[2]}</td>
							</tr>
						))}
					</tbody>
				</table>
			</CardBody>
			<PaginationButtons
				data={tableItems}
				label='items'
				setCurrentPage={setCurrentPage}
				currentPage={currentPage}
				perPage={perPage}
				setPerPage={setPerPage}
			/>
		</Card>			
	);
};

export default AOI;
