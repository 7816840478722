
export default {
    //rooturl: `http://${localStorage.getItem("company_code")}.localhost:8080/`,
    //ownroot: `http://${localStorage.getItem("company_code")}.localhost:8080/`,
  
    // defurl: "http://redbit.localhost:8080/",
    // rooturl: "http://redbit.localhost:8080/",
    ownurl: "https://mapi.exabit.co.id/",
    //rooturl: "http://35.209.217.88:8080/",
    // defurl: "http://hamster.redbit.co.id:8080/",
    // rooturl: "http://hamster.redbit.co.id:8080/",
    // defurl: "https://mapi.exabit.co.id/",
    defurl: "https://mapi.exabit.co.id/",
    rooturl: "https://mapi.exabit.co.id/",
    emailurl: 'https://emailgen.exabit.co.id/',
    //rooturl: "http://localhost:8080/",
    apikey: "Token "
  };
  