import React, { FC, useCallback, useContext, useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Input from '../../../components/bootstrap/forms/Input';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Button from '../../../components/bootstrap/Button';
import {
	fetchEmailFGSearch,
	fetchProfileSearch,
	pushProfilePass,
	verificationEmail,
} from '../../../api/login/index';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import qs from 'query-string';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import classNames from 'classnames';
import Logo from '../../../components/Logo';
import Spinner from '../../../components/bootstrap/Spinner';
import { encrypted } from '../../../api/monitoring';

const Verification = () => {
	const [authData, setAuthData] = useState<any>({});
	const [isLoading, setIsLoading] = useState(true);
	const [successVerification, setSuccessVerification] = useState(false);
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		let dataAuthEncrypted: any = qs.parse(location.search);
		let dataAuthEncryptedString = dataAuthEncrypted.auth
			.replace(/1sda45/g, '+')
			.replace(/32asdag32/g, '=')
			.replace(/pn230S/g, '/');
		if (dataAuthEncryptedString) {
			let bytes = CryptoJS.AES.decrypt(dataAuthEncryptedString, 'testing123');
			let decryptedData = bytes.toString(CryptoJS.enc.Utf8);
			let parsedDecryptedData = JSON.parse(decryptedData);
			let encryptedEmail = encrypted(parsedDecryptedData.email)
			setAuthData(JSON.parse(decryptedData));
			verificationEmail(encryptedEmail)
				.then((res) => {
					setIsLoading(false);
					setSuccessVerification(true);
					setTimeout(() => {
						navigate('/auth-pages/login');
					}, 7000);
				})
				.catch((err) => {
					setIsLoading(false);
					setSuccessVerification(false);
					setTimeout(() => {
						// navigate('/auth-pages/login');
					}, 7000);
				});
		} else {
			setIsLoading(false);
			setSuccessVerification(false);
			setTimeout(() => {
				// navigate('/auth-pages/login');
			}, 7000);
		}
	}, [location]);

	const handleTitleText = () => {
		if (isLoading) {
			return (
				<>
					<div className='text-center h1 fw-bold mt-5'>Loading</div>
					<div className='text-center h4 text-muted mb-5'>Please wait</div>
				</>
			);
		} else if (successVerification) {
			return (
				<>
					<div className='text-center h1 fw-bold mt-5'>Verification Success</div>
					<div className='text-center h4 text-muted mb-5'>
						We will navigate you to login
					</div>
				</>
			);
		} else {
			return (
				<>
					<div className='text-center h1 fw-bold mt-5'>Verification Failed</div>
					<div className='text-center h4 text-muted mb-5'>
						Please Ask Administrator to verify
					</div>
				</>
			);
		}
	};

	return (
		<PageWrapper isProtected={false} title={'Reset Password'} className={'bg-dark'}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='row g-4'>
									<div className='text-center my-5'>
										<Link
											to='/'
											className={classNames(
												'text-decoration-none  fw-bold display-2',
											)}>
											<Logo width={200} />
										</Link>
									</div>
									{handleTitleText()}
								</div>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Verification;
