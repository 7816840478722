import React from 'react';
import { RouteProps } from 'react-router-dom';
import { dashboardPagesMenu, demoPagesMenu, pageLayoutTypesPagesMenu, paymentPage, userPage } from '../menu';
import DefaultFooter from '../pages/_layout/_footers/DefaultFooter';

const footers: RouteProps[] = [
	// { path: pageLayoutTypesPagesMenu.blank.path, element: null },
	{ path: userPage.login.path, element: null },
	{ path: userPage.signUp.path, element: null },
	{ path: userPage.forgotPassword.path, element: null },
	{ path: userPage.resetPassword.path, element: null },
	// { path: dashboardPagesMenu.paidPlan.path, element: null },
	{ path: userPage.verification.path, element: null },
	{ path: paymentPage.paymentStatus.path, element: null },
	// { path: demoPagesMenu.page404.path, element: null },
	// { path: demoPagesMenu.knowledge.subMenu.grid.path, element: null },
	{ path: '*', element: <DefaultFooter /> },
];

export default footers;
