import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import Button from './bootstrap/Button';
import useDarkMode from '../hooks/useDarkMode';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
	CardSubTitle,
	CardFooter,
} from './bootstrap/Card';
import ListGroup, { ListGroupItem } from './bootstrap/ListGroup';
import { fetchCategoryDraw } from '../api/drawsave/index';
import { useSelector, useDispatch, connect, RootStateOrAny } from 'react-redux';

let layerAdd: any = [];

const drawCat: { name: string; value: string; layer: string }[] = [
	
	{
		name: "Point",
		value: "Point",
		layer: "poi_poi"
	},
	{
		name: "Line",
		value: "LineString",
		layer: "line_line"
	},
	{
	  name: "Polygon",
	  value: "Polygon",
	  layer: "polygon_polygon"
	}
];

const SavedDraw = () => {

	const dispatch = useDispatch();
    const layerMap = useSelector((state: RootStateOrAny) => state.layerMap);
	
	const { darkModeStatus } = useDarkMode();
	const [activeList, setActiveList] = useState<string>('Point');
	const [layerName, setLayerName] = useState<string>('poi_poi');	
	const [dataMap, setDataMap] = useState<{
		id: number;
		name:string;
		url: string;
		layer: string;
		type: string;
		status: string;
	}[]>([]);
	const [savedList, setSavedList] = useState<{
		name: string;
		description: string;
		id: number;
		types: string;
	}[]>([])

	useEffect(() => {
		fetchCategoryDraw("Point")
		.then((response) => {
			setSavedList(response.data.results);
			setActiveList("Point");	
			setLayerName("poi_poi");
		})
		.catch((error) => {
			console.log(error);
		});


	}, []);

	useEffect(() => {			
		if (typeof layerMap != "undefined" || []){
			if(layerMap.length > 0){
				setDataMap(layerMap[0]);
				 layerAdd = layerMap[0];
			}
		}
	}, [layerMap]);

	const handleOpenLegend = (id: number, name: string, url: string, layer: string, type: string) => {
		const newdata = {
			id: id,
			name: name,
			url: url,
			layer: layer,
			type: type,
			status: "add",
			checkedActive: true,
			checkedLegend: true,
			transparan: 1,
			yindex: layerAdd.length,
			download: 'active'
		};

		let index = _.findIndex(dataMap, _.pick(newdata, "id"));

		if (index !== -1) {
			layerAdd.splice(index, 1, newdata);
		} else {
			layerAdd.push(newdata);
		}

		dispatch({
			type: 'OPENRIGHT',
			payload: true,
		});			
		
		dispatch({
			type: 'LAYERMAP',
			payload: layerAdd,
		})

		dispatch({
			type: 'FOCUSLAYER',
			payload: {
				status: 'View Draw',
				name: newdata.name
			},
		})
	}

	const renderCat = () => {
		return (
			drawCat.map((item: any) => (
				<div>
					<ListGroup>
						<ListGroupItem 
							color="primary" 
							tag="button" 
							isActive ={activeList === item.value ? true : false}
							onClick={() => {
								setActiveList(item.value);
								setLayerName(item.layer)
								fetchCategoryDraw(item.value)
								.then((response) => {
									setSavedList(response.data.results);
								})
								.catch((error) => {
									console.log(error);
								});
							}}
						>
							{item.name}
						</ListGroupItem>
					</ListGroup>
				</div>
			))
		)
	}

	const renderList = (item: any, index: number) => {		
		return (
			<div key={index} className='col-md-4' style={{paddingTop: '5px'}}>
				<Card key={index} stretch className='mb-0'>
					<CardHeader>
						<CardLabel>
							<CardTitle>
								{item.name}
							</CardTitle>
							<CardSubTitle>{item.description}</CardSubTitle>
						</CardLabel>
					</CardHeader>				
					<CardFooter borderSize={3} borderColor='primary' className='justify-content-center'>
						<div>
							<Button key={item.id} color='primary' isLink 
								onClick={()=> 
									handleOpenLegend(item.id, item.name, `https://wms.redbit.co.id/geoserver/${localStorage.getItem('company_code')}/wms?CQL_FILTER=category='${item.name}'&`, layerName, 'geoserver')}
								>
								View on Map
							</Button>
						</div>
					</CardFooter>
				</Card>
			</div>
		)
	}

	return (
		<>
			<div className='row'>
				<div className='col-md-3'>
					{renderCat()}
				</div>
				<div className='col-md-9'>								
					<div className='row'>
						{savedList.map(renderList)}
					</div>
				</div>
			</div>			
		</>
	);
};

export default SavedDraw;
