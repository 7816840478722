import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import Button from './bootstrap/Button';
import useDarkMode from '../hooks/useDarkMode';
import { useNavigate } from 'react-router-dom';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from './bootstrap/OffCanvas';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
	CardSubTitle,
	CardFooter,
} from './bootstrap/Card';
import ListGroup, { ListGroupItem } from './bootstrap/ListGroup';import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from './bootstrap/Dropdown';
import Input from './bootstrap/forms/Input';
import FormGroup from './bootstrap/forms/FormGroup';
import Modal, {
	ModalHeader,
	ModalTitle,
	ModalBody,
	ModalFooter
} from './bootstrap/Modal';
import Checks, { ChecksGroup } from './bootstrap/forms/Checks';
import Collapse from './bootstrap/Collapse';
import { fetchLayer, fetchLayerCat, fetchCategory, pushAddCategory, pushAddLayer } from '../api/layer/index';
import { useSelector, useDispatch, connect, RootStateOrAny } from 'react-redux';

let layerAdd: any = [];
const AddLayers = () => {

	const dispatch = useDispatch();
    const layerMap = useSelector((state: RootStateOrAny) => state.layerMap);
	const navigate = useNavigate();
	
	const { darkModeStatus } = useDarkMode();
	const [id, setId] = useState("");
	const [name, setName] = useState("");
	const [url, setUrl] = useState("");
	const [layer, setLayer] = useState("");
	const [legend, setLegend] = useState("");
	const [category, setCategory] = useState("");
	const [type, setType] = useState("");
	const [urlpreview, setUrlpreview] = useState("");
	const [nameCat, setNameCat] = useState("");
	const [datatype, setDatatype] = useState("");
	const [activeList, setActiveList] = useState<string>('');
	const [addLayersForm, setAddLayersForm] = useState<boolean>(false);
	const [openAddCat, setOpenAddCat] = useState<boolean>(false);
	const [openAlert, setOpenAlert] = useState<boolean>(false);	
	const [isOpen, setIsOpen] = useState(false);	
	const [dataMap, setDataMap] = useState<{
		id: number;
		name:string;
		url: string;
		layer: string;
		type: string;
		status: string;
	}[]>([]);
	const [layerData, setLayerData] = useState<{
		id: number;
		name: string;
		url: string;
		layer: string;
		legend: string;
		category: string;
		type_service: string
	}[]>([])
	const [listData, setListData] = useState<{
		id: number;
		name: string;
		url: string;
		layer: string;
		legend: string;
		category: string;
		type_service: string;
	}[]>([])
	const [dataCategory, setDataCategory] = useState<{
		id: string;
		name: string;
		datatype: string;
	}[]>([])

	useEffect(() => {
		fetchLayer()
		.then((response) => {
			setLayerData(response.data.results);
			let listData = _.uniqBy(response.data.results, (obj: any) => obj.category);
			setActiveList(listData[0].category);
			fetchLayerCat(listData[0].category === null ? '' : listData[0].category)
			.then((response) => {
				setListData(response.data.results);
			})
			.catch((error) => {
				console.log(error);
			});
		})
		.catch((error) => {
			console.log(error);
		});

		fetchCategory()
		.then((response) => {
			setDataCategory(response.data.results)
		})
		.catch((error) => {
			console.log(error);
		});

	}, []);

	useEffect(() => {			
		if (typeof layerMap != "undefined" || []){
			if(layerMap.length > 0){
				setDataMap(layerMap[0]);
				 layerAdd = layerMap[0];
			}
		}
	}, [layerMap]);

	const handleOpenAdd = () => {
		setName("");
		setUrl("");
		setLayer("");
		setId("");
		setLegend("");
		setCategory("");
		setType("");
		setUrlpreview("");
		setAddLayersForm(!addLayersForm);
	};

	const handleOpenAddCat = () => {
		setNameCat("");
		setDatatype("");
		setOpenAddCat(!openAddCat);
	}

	const handleCloseAlert = () => {
		setOpenAlert(false);
		setAddLayersForm(false);
	};

	const handleOpenLegend = (id: number, name: string, url: string, layer: string, type: string) => {
		const newdata = {
			id: id,
			name: name,
			url: url,
			layer: layer,
			type: type,
			status: "add",
			checkedActive: true,
			checkedLegend: true,
			transparan: 1,
			yindex: layerAdd.length 
		};

		let index = _.findIndex(dataMap, _.pick(newdata, "id"));

		if (index !== -1) {
			layerAdd.splice(index, 1, newdata);
		} else {
			layerAdd.push(newdata);
		}

		dispatch({
			type: 'OPENRIGHT',
			payload: true,
		});			
		
		dispatch({
			type: 'LAYERMAP',
			payload: layerAdd,
		})

		dispatch({
			type: 'FOCUSLAYER',
			payload: {
				status: 'View on Map',
				name: newdata.name
			},
		})
	}

	const handleAddLayer = () => {
		let dateLayer = dayjs(new Date()).format('YYYY-MM-DD') ;
		
		pushAddLayer(dateLayer, name, url, layer, category, type)
        .then(response => {
          if (response != null) {
            setAddLayersForm(false)
            
				fetchLayer()
				.then((response) => {
					setLayerData(response.data.results)
				})
				.catch((error) => {
					console.log(error);
				});
          }
        })
        .catch(error => {			
			let msg = error.response.data;
			if(msg?.includes("reached maximum value of sequence")){				
				setOpenAlert(!openAlert);
			} else {
				console.log(error)
			}
		});
	}

	const handleAddCategory = () => {
		
		pushAddCategory(nameCat, datatype)
        .then(response => {
          if (response != null) {
            setOpenAddCat(false)
            
				fetchCategory()
				.then((response) => {
					setDataCategory(response.data.results)
				})
				.catch((error) => {
					console.log(error);
				});
          }
        })
        .catch(error => {});
	}

	const renderCategoryList = (e: any) => {
		return(
		  <option value={e.name}>{e.name}</option>
		)
	  }

	const renderList = () => {
		let listData = _.uniqBy(layerData, obj => obj.category);
		return (
			listData.map((item: any) => (
				<div>
					<ListGroup>
						<ListGroupItem 
							color="primary" 
							tag="button" 
							isActive ={activeList === item.category ? true : false}
							onClick={() => {
								setActiveList(item.category);
								fetchLayerCat(item.category === null ? '' : item.category)
								.then((response) => {
									setListData(response.data.results);
								})
								.catch((error) => {
									console.log(error);
								});
							}}
						>
							{item.category === null ? "No Category" : item.category}
						</ListGroupItem>
					</ListGroup>
				</div>
			))
		)
	}

	const renderListData = (e: any, index: number) => {
		return(
			<div key={e.id} className='col-md-4' style={{paddingTop: '5px'}}>
				<Card key={index} stretch className='mb-0'>
					<CardHeader>
						<CardLabel>
							<CardTitle>
								{e.name}
							</CardTitle>
							<CardSubTitle>{e.category === null ? "No Category" : e.category}</CardSubTitle>
						</CardLabel>
					</CardHeader>				
					<CardFooter borderSize={3} borderColor='primary' className='justify-content-center'>
						<div>
							<Button key={index} color='primary' isLink 
								onClick={()=> handleOpenLegend(e.id, e.name, e.url, e.layer, e.type_service)}
								>
								View on Map
							</Button>
						</div>
					</CardFooter>
				</Card>
			</div>
		)
	}	
	
	return (
		<>
			<div className='row'>
				<div className='col-md-3'>
					{renderList()}
				</div>
				<div className='col-md-9'>								
					<div className='row'>
						{listData.map(renderListData)}
					</div>
				</div>
			</div>
			<div className='row d-flex' style={{justifyContent: 'flex-end', position: 'relative', top: '114px', right: '12px'}}>
				<div className='col-auto'>
					<Button
						color='info'
						size='lg'
						icon='Add'
						isLight
						onClick={handleOpenAdd}
					/>
				</div>
			</div>
			<OffCanvas
				setOpen={setAddLayersForm}
				isOpen={addLayersForm}
				titleId='addLayer'
				isBackdrop={false}
				isBodyScroll
				placement='end'>
				<OffCanvasHeader setOpen={setAddLayersForm}>
					<OffCanvasTitle id='addLayers'>Add Layer</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>
					<div className='row g-4'>
						<div className='col-12'>
							<FormGroup id='name' label='Name'>
								<Input
									onChange={(e: any) => {setName(e.target.value)}}
									value={name}
								/>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='category' label='Category' style={{display: "flex", flexDirection: "column"}}>
								<select className="form-select" id="inputGroupSelect01"
									onChange={(e: any) => {setCategory(e.target.value)}}
									value={category}								
								>
									<option selected>Choose...</option>
									{dataCategory.map(renderCategoryList)}
								</select>
								<Button
									color='info'
									icon='Add'
									isLight
									onClick={() => handleOpenAddCat()}
									>
									Add Category
								</Button>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='type' label='Type Of Service'>
								<select className="form-select" id="inputGroupSelect01"
									onChange={(e: any) => {setType(e.target.value)}}
									value={type}								
								>
									<option selected>Choose...</option>
									<option value="geoserver">Geoserver</option>
									<option value="esri">Esri</option>
								</select>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='url' label='Url'>
								<Input
									onChange={(e: any) => {setUrl(e.target.value)}}
									value={url}
								/>
							</FormGroup>
						</div>
						{/* <div className='col-12'>
							<FormGroup id='urlpreview' label='Url Preview'>
								<Input
									onChange={(e: any) => {setUrlpreview(e.target.value)}}
									value={urlpreview}
								/>
							</FormGroup>
						</div> */}
						<div className='col-12'>
							<FormGroup id='layer' label='Layer'>
								<Input
									onChange={(e: any) => {setLayer(e.target.value)}}
									value={layer}
								/>
							</FormGroup>
						</div>
						{/* <div className='col-12'>
							<FormGroup id='legend' label='Legend'>
								<Input
									onChange={(e: any) => {setLegend(e.target.value)}}
									value={legend}
								/>
							</FormGroup>
						</div> */}
					</div>
				</OffCanvasBody>
				<div className='row m-0'>
					<div className='col-6 p-3'>
						<Button
							color='danger'
							className='w-100'
							onClick={() => setAddLayersForm(false)}>
							Cancel
						</Button>
					</div>
					<div className='col-6 p-3'>
						<Button
							color='info'
							className='w-100'
							onClick={handleAddLayer}>
							Add
						</Button>
					</div>
				</div>
			</OffCanvas>
		
			<Modal isOpen={openAddCat} setIsOpen={setOpenAddCat} titleId='add-category'>
				<ModalHeader>
					<ModalTitle id="add-category-title">
						Add Category
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='col-12'>
						<FormGroup id='name' label='Category Name'>
							<Input
								onChange={(e: any) => {setNameCat(e.target.value)}}
								value={nameCat}
							/>
						</FormGroup>
					</div>					
					<div className='col-12'>
						<FormGroup id='type' label='Type Of Service'>
							<select className="form-select" id="inputGroupSelect01"
								onChange={(e: any) => {setDatatype(e.target.value)}}
								value={datatype}								
							>
								<option selected>Choose...</option>
								<option value="geoserver">Geoserver</option>
								<option value="esri">Esri</option>
							</select>
						</FormGroup>
					</div>
				</ModalBody>
				<ModalFooter>
					<div className='row m-0'>
						<div className='col-6 p-3'>
						<Button
							color="danger"
							onClick={() => setOpenAddCat(false)}
							>
							Cancel
						</Button>
						</div>
						<div className='col-6 p-3'>
							<Button
								color="info"
								onClick={handleAddCategory}
								>
								Add
							</Button>
						</div>
					</div>					
				</ModalFooter>
			</Modal>

			<Modal isOpen={openAlert} setIsOpen={setOpenAlert} titleId='layer-alert'>
					<ModalHeader>
						<ModalTitle id='alert-title'>Add Layer</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<p>You are reached maximum limit of layer in free plan!</p>
					</ModalBody>
					<ModalFooter>
						<div className='row m-0'>
							<div className='col-5 p-3'>
								<Button color='danger' onClick={() => handleCloseAlert()}>
									Cancel
								</Button>
							</div>
							<div className='col-7 p-3'>
								<Button color='info' onClick={() => navigate('/plan')}>
									Go to Plan
								</Button>
							</div>
						</div>
					</ModalFooter>
				</Modal>

		</>
	);
};

export default AddLayers;
