import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { useMeasure } from 'react-use';
// @ts-ignore
import { isWebpSupported } from 'react-image-webp/dist/utils';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import Page from '../../../layout/Page/Page';
import Button from '../../../components/bootstrap/Button';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Logo from '../../../components/Logo';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import {
	editOrder,
	fetchCompanyProfile,
	getOrder,
	postOrder,
	tokenizationOrder,
	transactionStatus,
} from '../../../api/payment';
import { Buffer } from 'buffer';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import { fetchProfile, pushProfile, pushProfilePictureUser } from '../../../api/login';
import UserImage4 from '../../../assets/img/wanna/wanna4.png';
import config from '../../../config';
import { Link } from 'react-router-dom';
import useSortableData from '../../../hooks/useSortableData';
import PaginationButtons, {
	dataPagination,
	PER_COUNT,
} from '../../../components/PaginationButtons';

// const ROOT_URL = Config.defurl;
const ROOT_URL = `https://${localStorage.getItem('company_code')}.exabit.co.id/`;
interface iBillingHistory {
	id: number;
	order_id: number;
	quantity: number;
	price: number;
	datetime: string;
	status: string;
	company_id: number;
	user_id: number;
	plan_id: number;
	plan_name: string;
}

interface iCompanyProfile {
	id: number;
	company_name: string;
	company_address: string;
	company_telp: string;
	company_code: string;
	company_email: string;
	company_img: string;
	plan_id: number;
	last_payment: string;
	next_payment: string;
	npwp: string;
	plan_name: string;
	order_id: string;
}

const Billing = () => {
	const [ref, { height }] = useMeasure<HTMLDivElement>();
	const location = useLocation();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [nameUser, setNameUser] = useState('');
	const [companyNameUser, setCompanyNameUser] = useState('');
	const [emailUser, setEmailUser] = useState('');
	const [descriptionUser, setDescriptionUser] = useState('');
	const [companyProfile, setCompanyProfile] = useState<iCompanyProfile>({
		id: 0,
		company_name: '',
		company_address: '',
		company_telp: '',
		company_code: '',
		company_email: '',
		company_img: '',
		plan_id: 0,
		last_payment: '',
		next_payment: '',
		npwp: '',
		plan_name: '',
		order_id: '',
	});
	const [categoryTable, setcategoryTable] = useState([
		'order_id',
		'plan_name',
		'price',
		'datetime',
		'status',
		'invoice',
	]);
	const [billingHistory, setBillingHistory] = useState<iBillingHistory[]>([]);

	const [currentPageBilling, setCurrentPageBilling] = useState<number>(1);
	const [perPageBilling, setPerPageBilling] = useState<number>(PER_COUNT['10']);
	var { items, requestSort, getClassNamesFor } = useSortableData(billingHistory);
	const itemsBilling = items;
	const onCurrentPageBillingItems = dataPagination(
		itemsBilling,
		currentPageBilling,
		perPageBilling,
	);
	useEffect(() => {
		fetchCompanyProfile(localStorage.getItem('company_code'))
			.then((res) => {
				if (res.data.results.length !== 0) setCompanyProfile(res.data.results[0]);
			})
			.catch((err) => {
				console.log(err);
			});
		getOrder(localStorage.getItem('company_code'))
			.then((res) => {
				setBillingHistory(res.data.results);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	return (
		<PageWrapper ref={ref} title='Profile'>
			<Page>
				<div className='display-4 text-center fw-bold mb-4 position-relative'>Billing</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						gap: 16,
					}}>
					<div>
						<div
							className='border border-primary rounded-1'
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: 16,
								background: '#a99cfa',
								padding: 16,
								width: 200,
							}}>
							<Link to={'/profile'}>
								<Button
									color='primary'
									style={{
										width: '100%',
									}}>
									Profile
								</Button>
							</Link>
							<Link to={'/profile/account-management'}>
								<Button
									style={{
										width: '100%',
									}}
									color='primary'>
									Account Management
								</Button>
							</Link>
							{/* <Link to={'/profile/billing'}> */}
							<Button
								style={{
									width: '100%',
								}}
								isDisable
								color='primary'>
								Billing
							</Button>
							{/* </Link> */}
							{localStorage.getItem('is_superuser') && (
								<Link to={'/profile/company'}>
									<Button
										style={{
											width: '100%',
										}}
										color='primary'>
										Company
									</Button>
								</Link>
							)}
						</div>
					</div>
					<div
						className='border border-primary rounded-1'
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: 16,
							background: '#a99cfa',
							padding: 32,
							alignItems: 'center',
							width: '100%',
						}}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								gap: 48,
								width: '100%',
							}}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									gap: 16,
									justifyContent: 'stretch',
									alignItems: 'center',
									padding: 8,
								}}>
								<p
									className='text-center fw-bold'
									style={{
										margin: 0,
										fontSize: 24,
										color: '#F9F9FD',
									}}>
									Plan
								</p>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										gap: 16,
									}}>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											gap: 16,
											alignItems: 'center',
										}}>
										<p
											style={{
												margin: 0,
												fontSize: 16,
												color: '#F9F9FD',
												width: 250,
											}}>
											Plan
										</p>
										<p
											style={{
												margin: 0,
												fontSize: 16,
												color: '#F9F9FD',
											}}>
											: {companyProfile.plan_name}
										</p>
									</div>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											gap: 16,
											alignItems: 'center',
										}}>
										<p
											style={{
												margin: 0,
												fontSize: 16,
												color: '#F9F9FD',
												width: 250,
											}}>
											Next Payment
										</p>
										<p
											style={{
												margin: 0,
												fontSize: 16,
												color: '#F9F9FD',
											}}>
											: {companyProfile.next_payment}
										</p>
									</div>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											gap: 16,
											alignItems: 'center',
										}}>
										<p
											style={{
												margin: 0,
												fontSize: 16,
												color: '#F9F9FD',
												width: 250,
											}}>
											NPWP
										</p>
										<p
											style={{
												margin: 0,
												fontSize: 16,
												color: '#F9F9FD',
											}}>
											: {companyProfile.npwp}
										</p>
									</div>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											gap: 16,
											alignItems: 'center',
										}}>
										<Link
											to={`/payment?plan=${companyProfile.plan_name}`}
											style={{
												width: '100%',
											}}
											hidden={companyProfile.plan_id === 0}>
											<Button
												style={{
													width: '100%',
												}}
												color='info'>
												Renew Payment
											</Button>
										</Link>
									</div>
								</div>
							</div>
							<div
								style={{
									border: '1px solid white',
								}}
							/>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									gap: 16,
									justifyContent: 'stretch',
								}}>
								<p
									className='text-center fw-bold'
									style={{
										margin: 0,
										fontSize: 24,
										color: '#F9F9FD',
									}}>
									Billing Account
								</p>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										gap: 16,
										alignItems: 'center',
									}}>
									<p
										style={{
											margin: 0,
											fontSize: 16,
											color: '#F9F9FD',
											width: 250,
										}}>
										Name
									</p>
									<p
										style={{
											margin: 0,
											fontSize: 16,
											color: '#F9F9FD',
										}}>
										: {companyProfile.company_name}
									</p>
								</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										gap: 16,
										alignItems: 'center',
									}}>
									<p
										style={{
											margin: 0,
											fontSize: 16,
											color: '#F9F9FD',
											width: 250,
										}}>
										Contact Number
									</p>
									<p
										style={{
											margin: 0,
											fontSize: 16,
											color: '#F9F9FD',
										}}>
										: {companyProfile.company_telp}
									</p>
								</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										gap: 16,
										alignItems: 'center',
									}}>
									<p
										style={{
											margin: 0,
											fontSize: 16,
											color: '#F9F9FD',
											width: 250,
										}}>
										Email
									</p>
									<p
										style={{
											margin: 0,
											fontSize: 16,
											color: '#F9F9FD',
										}}>
										: {companyProfile.company_email}
									</p>
								</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										gap: 16,
										alignItems: 'center',
									}}>
									<p
										style={{
											margin: 0,
											fontSize: 16,
											color: '#F9F9FD',
											width: 250,
										}}>
										Address
									</p>
									<p
										style={{
											margin: 0,
											fontSize: 16,
											color: '#F9F9FD',
										}}>
										: {companyProfile.company_address}
									</p>
								</div>
							</div>
						</div>
						<div
							style={{
								display: 'flex',
								width: '100%',
								flexDirection: 'column',
								gap: 16,
							}}>
							<p
								className='fw-bold'
								style={{
									margin: 0,
									fontSize: 24,
									color: '#F9F9FD',
									width: '100%',
								}}>
								History Billing
							</p>
							<div>
								<table
									className='table table-modern table-hover'
									style={{
										margin: 0,
									}}>
									<thead>
										<tr>
											<th scope='col'>Order ID</th>
											<th scope='col'>Plan Name</th>
											<th scope='col'>Price</th>
											<th scope='col'>Date Time</th>
											<th scope='col'>Status</th>
											<th scope='col'>Invoice</th>
										</tr>
									</thead>
									<tbody>
										{dataPagination(
											itemsBilling,
											currentPageBilling,
											perPageBilling,
										).map((item) => (
											<tr key={item.id}>
												{categoryTable.map((stateName) => (
													<td>
														{stateName === 'datetime'
															? moment(item[stateName]).format('LLL')
															: item[stateName]}
													</td>
												))}
											</tr>
										))}
									</tbody>
								</table>
								<PaginationButtons
									data={itemsBilling}
									label='items'
									setCurrentPage={setCurrentPageBilling}
									currentPage={currentPageBilling}
									perPage={perPageBilling}
									setPerPage={setPerPageBilling}
								/>
							</div>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Billing;
