import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { useMeasure } from 'react-use';
// @ts-ignore
import { isWebpSupported } from 'react-image-webp/dist/utils';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import Page from '../../../layout/Page/Page';
import Button from '../../../components/bootstrap/Button';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Logo from '../../../components/Logo';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import {
	editOrder,
	getOrder,
	postOrder,
	tokenizationOrder,
	transactionStatus,
} from '../../../api/payment';
import logo from '../../../components/icon/icon-img/logo.png';
import { Buffer } from 'buffer';

const PaymentStatus = () => {
	const [ref, { height }] = useMeasure<HTMLDivElement>();
	const location = useLocation();
	const navigate = useNavigate();
	const [status, setStatus] = useState<{
		// id: number;
		order_id: string;
		plan_id: string;
		plan_name: string;
		quantity: number;
		price: string;
		datetime: string;
		status: string;
		company_code: string;
		user_id: number;
	}>({
		// id: 0,
		order_id: '',
		plan_id: '',
		plan_name: '',
		quantity: 0,
		price: '',
		datetime: '',
		status: '',
		company_code: "",
		user_id: 0,
	});
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const { order_id, status_code, transaction_status }: any = qs.parse(location.search);
		handleCheckOrder(order_id);
	}, []);

	const handleCheckOrder = async (order_id: string) => {
		let statusOrder = {
			id: 0,
			order_id: '',
			plan_id: '',
			plan_name: '',
			quantity: 0,
			price: '',
			datetime: '',
			status: '',
			company_code: "",
			user_id: 0,
			user_limit: 0,
			data_limit: 0,
		};
		await getOrder(order_id)
			.then(async (res) => {
				await setStatus(res.data.results[0]);
				statusOrder = res.data.results[0];
			})
			.catch((err) => {
				console.log(err);
			});
		if (statusOrder.status === 'settlement') {
			navigate('/');
		} else {
			let midtransOrder = {
				status_code: '',
				transaction_id: '',
				gross_amount: '',
				currency: '',
				order_id: '',
				payment_type: '',
				signature_key: '',
				transaction_status: '',
				fraud_status: '',
				status_message: '',
				merchant_id: '',
				va_numbers: [
					{
						bank: '',
						va_number: '',
					},
				],
				payment_amounts: [],
				transaction_time: '',
				settlement_time: '',
				expiry_time: '',
			};
			await transactionStatus(order_id)
				.then(async (res) => {
					midtransOrder = JSON.parse(res.data);
				})
				.catch((err) => {
					console.log(err);
				});
			if (statusOrder.status !== midtransOrder.transaction_status) {
				let newStatusOrder = {
					company_code: statusOrder.company_code,
					datetime: statusOrder.datetime,
					// id: statusOrder.id,
					order_id: statusOrder.order_id,
					plan_id: statusOrder.plan_id,
					plan_name: statusOrder.plan_name,
					price: statusOrder.price,
					quantity: statusOrder.quantity,
					status: midtransOrder.transaction_status,
					user_id: statusOrder.user_id,
					user_limit: statusOrder.user_limit,
					data_limit: statusOrder.data_limit
				};
				setStatus(newStatusOrder);
				editOrder(newStatusOrder);
			}
		}
		setLoading(false);
		setTimeout(() => {
			navigate('/');
		}, 7000);
	};

	return (
		<PageWrapper ref={ref} title='Payment Status'>
			<Page>
				<div
					style={{
						height: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}>
					<div
						className={classNames(
							'col-lg-12',
							'col-12',
							'd-flex',
							'align-items-center',
							'justify-content-center',
							// classes.introContentWrapper,
						)}
						style={{
							marginBottom: 20,
						}}>
						<div>
							<div className='display-4 text-center fw-bold mb-4 position-relative'>
								{loading ? (
									<>Loading Status Payment...</>
								) : (
									<>
										Your Payment Status is{' '}
										<span className='text-primary'>{status.status === 'settlement' && "success"}</span>
									</>
								)}
							</div>
							<div className='display-4 text-center fw-bold mb-4 position-relative'>
								{status.status === 'settlement' &&
									'Thank for purchasing our services'}
								{status.status === 'deny' ||
									status.status === 'expire' ||
									(status.status === 'failure' && 'Please contact Administrator')}
							</div>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default PaymentStatus;
