import axios from "axios";
import Config from "../../config";
const API_KEY = Config.apikey;
// const ROOT_URL = Config.defurl;
const ROOT_URL = `https://${localStorage.getItem('company_code')}.exabit.co.id/`;
import CryptoJS from 'crypto-js';

export function cekAuth() {
  return {
    headers: { Authorization: API_KEY + localStorage.getItem("token") }
  };
}

export function encrypted(text) {
  var iv = CryptoJS.lib.WordArray.random(16);
  var key = CryptoJS.enc.Base64.parse("redbitefwlPNVkvTHwfs6w==");

  var encrypted = CryptoJS.AES.encrypt(text.toString(), key, { iv: iv });
  var joinedData = iv.clone().concat(encrypted.ciphertext);
  var joinedDataB64 = CryptoJS.enc.Base64.stringify(joinedData);

  return encodeURIComponent(joinedDataB64);
}
 

export function fetchTrackJumpPeriode(
    startDate,
    endDate,
    limit,
    offset,
    catid,
    orderby,
    sort,
    search
  ) {
    const url = `${ROOT_URL}trackjump/trackjumpperiodesx/?catid=${encrypted(
      catid
    )}&startdate=${encrypted(startDate)}&enddate=${encrypted(
      endDate
    )}&limit=${encrypted(limit)}&offset=${encrypted(offset)}&search=${encrypted(
      search
    )}&orderby=${encrypted(orderby)}&ascdsc=${encrypted(sort)}`;
    const request = axios.get(url, cekAuth());
    // pushlogging("get " + url);
    return request;
  }

  export function fetchTrackSpeedPeriode(
    startDate,
    endDate,
    limit,
    offset,
    catid,
    orderby,
    sort,
    search
  ) {
    const url = `${ROOT_URL}trackspeed/trackspeedperiodex/?catid=${encrypted(
      catid
    )}&startdate=${encrypted(startDate)}&enddate=${encrypted(
      endDate
    )}&limit=${encrypted(limit)}&offset=${encrypted(offset)}&search=${encrypted(
      search
    )}&orderby=${encrypted(orderby)}&ascdsc=${encrypted(sort)}`;
    const request = axios.get(url, cekAuth());
    // pushlogging("get " + url);
    return request;
  }

  export function fetchTrackDraughtPeriode(
    startDate,
    endDate,
    limit,
    offset,
    orderby,
    sort,
    search
  ) {
    const url = `${ROOT_URL}draught/draughtdataperiodex/?startdate=${encrypted(
      startDate
    )}&enddate=${encrypted(endDate)}&limit=${encrypted(limit)}&offset=${encrypted(
      offset
    )}&search=${encrypted(search)}&orderby=${encrypted(
      orderby
    )}&ascdsc=${encrypted(sort)}`;
    const request = axios.get(url, cekAuth());
    // pushlogging("get " + url);
    return request;
  }

  export function fetchInactive(
    category_id,
    periode, 
    source,
  ) {
    const url = `${ROOT_URL}inactive/inactivecatshiptype/?category_id=${encrypted(
      category_id
    )}&periode=${encrypted(periode)}&source=${encrypted(source)}`;
    const request = axios.get(url, cekAuth());
    // pushlogging("get " + url);
    return request;
  }

  export function fetchInactivedata(
    category_id,
    chartDuration,
    perPage,
    offset,
    orderby,
    sort,
    search,
    chartSouce,
    startdate,
    enddate
  )  {
    const url = `${ROOT_URL}inactive/inactivedataperiodex/?category_id=${encrypted(
      category_id
    )}&periode=${encrypted(chartDuration)}&limit=${encrypted(perPage)}&offset=${encrypted(
      offset
    )}&search=${encrypted(search)}&orderby=${encrypted(
      orderby
    )}&ascdsc=${encrypted(sort)}&source=${encrypted(chartSouce)}&startdate=${encrypted(startdate)}&enddate=${encrypted(enddate)}`;
    const request = axios.get(url, cekAuth());
    // pushlogging("get " + url);
    return request;
  }
  
  export function fetchApproachChart(
    category_id, 
  ) {
    const url = `${ROOT_URL}aisidle/aisidleshiptype/?category_id=${encrypted(category_id)}`;
    const request = axios.get(url, cekAuth());
    // pushlogging("get " + url);
    return request;
  }

  export function fetchApproachData(
    category_id, 
    perPage,
    offset,
  ) {
    const url = `${ROOT_URL}aisidle/aisidle/?cat=${category_id}&limit=${perPage}&offset=${offset}`;
    const request = axios.get(url, cekAuth());
    // pushlogging("get " + url);
    return request;
  }

  export function fetchSpeedCat(category) {
    let url = `${ROOT_URL}catspeed/catspeed/`;
    const request = axios.get(url, cekAuth());
    return request;
  }

  export function fetchJumpingCat(category) {
    let url = `${ROOT_URL}catjumping/catjumping/`;
    const request = axios.get(url, cekAuth());
    return request;
  }

  export function fetchCatDraugh(category) {
    let url = `${ROOT_URL}catdraugh/catdraugh/`;
    const request = axios.get(url, cekAuth());
    return request;
  }

  export function fetchInactiveCat(category) {
    let url = `${ROOT_URL}catinactive/catinactive/`;
    const request = axios.get(url, cekAuth());
    return request;
  }

  export function fetchApproachCat(category) {
    let url = `${ROOT_URL}cataisidle/cataisidle/`;
    const request = axios.get(url, cekAuth());
    return request;
  }

  export function fetchZonetrackingCat(category) {
    let url = `${ROOT_URL}drawsave/drawsaveusefor/`;
    const request = axios.get(url, cekAuth());
    return request;
  }

  export function fetchTracking(mmsi, startDate, endDate, source_api) {
    const url = `${ROOT_URL}shipapits/shiptrackbt/?mmsi=${encrypted(
      mmsi
    )}&startDate=${encrypted(startDate)}&endDate=${encrypted(
      endDate
    )}&source_api=${encrypted(source_api)}`;
    const request = axios.get(url, cekAuth());
    return request;
  }

  export function fetchFleetPosition(source) {
    const url = `${ROOT_URL}shipapirt/shipapirtptmnnewrev/?sourceAis=${encrypted(source)}&user_id=${encrypted(localStorage.getItem('id'))}
    &userlevel=${encrypted(localStorage.getItem('userlevel'))}`;
    const request = axios.get(url, cekAuth());
    return request;
  }

  export function fetchZonaVolume(
    periode,
    sourcedata,
    usefor,
    startDate,
    endDate
  ) {
    const url = `${ROOT_URL}zonetracking/zonevolume/?user_id=${encrypted(
      localStorage.getItem("id")
    )}&periode=${encrypted(periode)}&sourcedata=${encrypted(
      sourcedata
    )}&usefor=${encrypted(usefor)}&startdate=${encrypted(
      startDate
    )}&enddate=${encrypted(endDate)}`;
    const request = axios.get(url, cekAuth());
    return request;
  }

  export function fetchZoneTrackingLast(startDate, endDate, usefor) {
    const url = `${ROOT_URL}zonetracking/zonetrackinglast/?startdate=${encrypted(
      startDate
    )}&enddate=${encrypted(endDate)}&usefor=${encrypted(usefor)}`;
    const request = axios.get(url, cekAuth());
    return request;
  }

  export function fetchShipZona(periode, sourcedata, usefor, startDate, endDate) {
    const url = `${ROOT_URL}zonetracking/zonetrackingnew/?user_id=${encrypted(
      localStorage.getItem("id")
    )}&periode=${encrypted(periode)}&sourcedata=${encrypted(
      sourcedata
    )}&usefor=${encrypted(usefor)}&startdate=${encrypted(
      startDate
    )}&enddate=${encrypted(endDate)}`;
    const request = axios.get(url, cekAuth());
    return request;
  }

  export function fetchZonabySearch(search) {
    const url = `${ROOT_URL}drawsave/drawsave/?search=${search}`;
    const request = axios.get(url, cekAuth());
    return request;
  }

  export function fetchPtmGpsapirtSearchStatus(status) {
    const url = `${ROOT_URL}gpsapirt/gpsapirt/?search=${status}&limit=1000`;
    const request = axios.get(url, cekAuth());
    return request;
  }

  export function fetchInactiveFleet(cat, periode, source) {
    const url = `${ROOT_URL}inactive/inactivedata/?category_id=${encrypted(
			cat,
	 	)}&periode=${encrypted(periode)}&source=${encrypted(source)}&company_code=${encrypted(localStorage.getItem('company_code'))}`;
    const request = axios.get(url, cekAuth());
    return request;
  }

  export function fetchTrackSpeed(catid, periode) {
    const url = `${ROOT_URL}trackspeed/trackspeednew/?catid=${encrypted(
			catid,
		)}&periode=${encrypted(periode)}&company_code=${encrypted(localStorage.getItem('company_code'))}`;
    const request = axios.get(url, cekAuth());
    return request;
  }