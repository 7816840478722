export default function(state = [
//   [
//     {
//       id: 16,
// 			name: "Prodia Building lt 5",
// 			url: "https://wms.redbit.co.id/geoserver/redbit/wms?",
// 			layer: "redbit:lantai5",
// 			type: "geoserver",
// 			status: "add",
// 			checkedActive: true,
// 			checkedLegend: true,
// 			transparan: 1,
//     },
//     {
//       id: 12,
// 			name: "Gateway",
// 			url: "https://wms.redbit.co.id/geoserver/redbit/wms?",
// 			layer: "redbit:anchor",
// 			type: "geoserver",
// 			status: "add",
// 			checkedActive: true,
// 			checkedLegend: true,
// 			transparan: 1,
//     },
//     {
//       id: 14,
// 			name: "Device real location",
// 			url: "https://wms.redbit.co.id/geoserver/redbit/wms?",
// 			layer: "redbit:device location",
// 			type: "geoserver",
// 			status: "add",
// 			checkedActive: true,
// 			checkedLegend: true,
// 			transparan: 1,
//     }
//   ]
], action) {
    if(action.type === "LAYERMAP") { 
       return [action.payload] ; 
     } 
     return state;
   }