import React, { FC, useCallback, useContext, useState } from 'react';
import Input from '../../../components/bootstrap/forms/Input';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Button from '../../../components/bootstrap/Button';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import axios from 'axios';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Logo from '../../../components/Logo';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Spinner from '../../../components/bootstrap/Spinner';

const ForgotPassword = () => {
	const [email, setEmail] = useState('');
	const [errorStatus, setErrorStatus] = useState<string>('');
	const [isLoading, setIsLoading] = useState(false);
	const [doneStatus, setDoneStatus] = useState(false);

	const handleConfirm = async () => {
		setErrorStatus('');
		setIsLoading(true);
		await axios
			.post('https://emailgen.exabit.co.id/forgot-password', {
				email: email,
			})
			.then((res) => {
				setDoneStatus(true);
				setIsLoading(false);
				console.log(res);
			})
			.catch((err) => {
				console.log(err);
				setIsLoading(false);
				setErrorStatus(err.response.data.error.message);
			});
	};

	return (
		<PageWrapper isProtected={false} title={'Forgot Password'} className={'bg-dark'}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='row g-4'>
									<div className='text-center my-5'>
										<Link
											to='/'
											className={classNames(
												'text-decoration-none  fw-bold display-2',
											)}>
											<Logo width={200} />
										</Link>
									</div>
									{doneStatus ? (
										<>
											<div className='text-center h1 fw-bold mt-5'>
												Email sent!
											</div>
											<div className='text-center h4 text-muted mb-5'>
												Please check your email to continue
											</div>
										</>
									) : (
										<>
											<div className='text-center h1 fw-bold mt-5'>
												Forgot Password
											</div>
											<div className='text-center h4 text-muted mb-5'>
												Input your email to get email confirmation
											</div>
											<div className='col-12'>
												<FormGroup id='email' label='Email'>
													<Input
														onChange={(e: any) => {
															setEmail(e.target.value);
														}}
														value={email}
													/>
												</FormGroup>
											</div>

											<div className='col-12'>
												<FormGroup id='confirmPassword'>
													<Button
														color='warning'
														className='w-100 py-3'
														onClick={() => {
															handleConfirm();
														}}
														isDisable={!email}>
														{isLoading && (
															<Spinner isSmall inButton isGrow />
														)}
														Send Email Confirmation
													</Button>
												</FormGroup>
												<p
													style={{
														color: '#ea868f',
														marginTop: '10px',
													}}
													hidden={!errorStatus}>
													{errorStatus}
												</p>
											</div>
										</>
									)}
								</div>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
		// <div style={{ width: '50%', margin: 'auto', padding: 10 }}>

		// </div>
	);
};

export default ForgotPassword;
