import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { useMeasure } from 'react-use';
// @ts-ignore
import { isWebpSupported } from 'react-image-webp/dist/utils';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import Page from '../../../layout/Page/Page';
import Button from '../../../components/bootstrap/Button';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Logo from '../../../components/Logo';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { fetchCompanyProfile, postOrder, tokenizationOrder } from '../../../api/payment';

const Payment = () => {
	const [ref, { height }] = useMeasure<HTMLDivElement>();
	const location = useLocation();
	const navigate = useNavigate();
	const [plan, setPlan] = useState<{
		id: number;
		price_type: string;
		price: string;
		service_description: string;
		data_limit: number;
		user_limit: number;
	}>({
		id: 0,
		price: '',
		price_type: '',
		service_description: '',
		data_limit: 0,
		user_limit: 0,
	});
	const [loadingTransaction, setLoadingTransaction] = useState(false);
	const [companyData, setCompanyData] = useState<{
		id: number;
		company_name: string;
		company_address: string;
		company_telp: string;
		company_code: string;
		company_email: string;
		company_img: string;
		npwp: string;
		plan_name: string | null;
		plan_id: number;
		last_payment: string | null;
		next_payment: string | null;
	}>({
		id: 0,
		company_name: '',
		company_address: '',
		company_telp: '',
		company_code: '',
		company_email: '',
		company_img: '',
		npwp: '',
		plan_name: null,
		plan_id: 1,
		last_payment: null,
		next_payment: null,
	});

	useEffect(() => {
		const plan: any = qs.parse(location.search);
		if (plan.plan) {
			axios.get(`https://mapi.exabit.co.id/pricelist/pricelist/`).then((res) => {
				const plans: {
					id: number;
					price_type: string;
					price: string;
					service_description: string;
					data_limit: number;
					user_limit: number;
				}[] = res.data.results;
				const selectedPlans = _.find(
					plans,
					(item: {
						id: number;
						price_type: string;
						price: string;
						service_description: string;
						data_limit: number;
						user_limit: number;
					}) => {
						return item.price_type === plan.plan;
					},
				);
				if (selectedPlans) {
					setPlan(selectedPlans);
					fetchCompanyProfile(localStorage.getItem('company_code')).then((res) => {
						if (res.data.results.length !== 0) setCompanyData(res.data.results[0]);
						else navigate('/');
					});
				} else navigate('/');
			});
		} else navigate('/');
	}, []);

	useEffect(() => {
		const snapScript = 'https://app.midtrans.com/snap/snap.js';
		const clientKey = process.env.REACT_APP_MIDTRANS_PUBLIC_CLIENT;

		const script = document.createElement('script');
		script.src = snapScript;
		script.setAttribute('data-client-key', clientKey!);
		script.async = true;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	const handleOrder = () => {
		setLoadingTransaction(true);
		let order_id = `${Math.floor(10000000 + Math.random() * 90000000)}`;
		let order = {
			order_id: order_id,
			plan_id: plan.id.toString(),
			plan_name: plan.price_type,
			quantity: 1,
			price: `${plan.price}`,
			datetime: moment(),
			status: 'pending',
			company_code: localStorage.getItem('company_code'),
			user_id: localStorage.getItem('id'),
			data_limit: plan.data_limit,
			user_limit: plan.user_limit,
		};
		postOrder(order)
			.then((res) => {
				checkout(order_id);
			})
			.catch((err) => {
				console.log(err);
				setLoadingTransaction(false);
			});
	};

	const checkout = async (order_id: string) => {
		let customer_details = {
			first_name: companyData.company_name,
			// last_name: companyData.company_name,
			email: companyData.company_email,
			phone: companyData.company_telp,
		};
		tokenizationOrder(order_id, plan.price_type, plan.price, customer_details)
			.then(async (response) => {
				const requestData = await response.data.data;
				let test: any = window;
				setLoadingTransaction(false);
				await test.snap.pay(requestData, {
					onSuccess: (result: any) => {
						navigate(`/payment-status?order_id=${order_id}`);
					},
					onPending: function (result: any) {
						navigate(`/payment-status?order_id=${order_id}`);
					},
					onError: function (result: any) {
						navigate(`/payment-status?order_id=${order_id}`);
					},
					onClose: function () {
						console.log('customer closed the popup without finishing the payment');
					},
				});
				// window.snap.pay(requestData.token);
				// alert("Checkout SNAP! 🌟");
			})
			.catch((err) => {
				console.log(err);
				setLoadingTransaction(false);
			});
	};

	return (
		<PageWrapper ref={ref} title='Payment'>
			<SubHeader>
				<SubHeaderLeft>
					<div
						style={{
							height: '20px',
						}}>
						<Logo height={100} />
					</div>
				</SubHeaderLeft>
			</SubHeader>
			<Page>
				<div id='intro' className='scroll-margin'>
					<div>
						<div
							className={classNames(
								'col-lg-12',
								'col-12',
								'd-flex',
								'align-items-center',
								'justify-content-center',
								// classes.introContentWrapper,
							)}
							style={{
								marginBottom: 20,
							}}>
							<div>
								<div className='display-4 text-center fw-bold mb-4 position-relative'>
									Payment <span className='text-primary'>Plan</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div id='bootstrap' className='row h-fluid-min-lg-100 scroll-margin gx-5'>
					<div className='col-6'>
						<Card>
							<CardBody className='h-100 p-5'>
								<div
									className='row d-flex align-items-center h-100'
									style={{
										gap: '8px',
									}}>
									<h1>Choosen Plan</h1>
									<h2>Plan Type: {plan.price_type}</h2>
									<h2>Price: Rp.{plan.price} per Month</h2>
									<h3>{plan.service_description}</h3>
								</div>
							</CardBody>
						</Card>
					</div>
					<div className='col-6'>
						<div
							className='row d-flex align-items-center'
							style={{
								gap: '8px',
								height: 50,
							}}>
							<h1>Payment</h1>
							<h2>Tipe Plan: {plan.price_type}</h2>
							<h2>Price: Rp.{plan.price}</h2>
							<Button
								type='button'
								tag='a'
								color='info'
								isDisable={loadingTransaction}
								onClick={() => {
									handleOrder();
								}}>
								{loadingTransaction ? 'Loading...' : 'Pay'}
							</Button>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Payment;
