import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import Button from './bootstrap/Button';
import useDarkMode from '../hooks/useDarkMode';
import OffCanvas, { OffCanvasBody, OffCanvasHeader } from './bootstrap/OffCanvas';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
	CardSubTitle,
	CardFooter,
} from './bootstrap/Card';
import ListGroup, { ListGroupItem } from './bootstrap/ListGroup';import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from './bootstrap/Dropdown';
import { fetchBookmark } from '../api/layer/index';
import osm from '../assets/osm.png';
import dark from '../assets/dark.png';
import { useSelector, useDispatch, connect, RootStateOrAny } from 'react-redux';
import AddLayers from '../components/AddLayers'
import AddServices from '../components/AddServices'
import AddData from '../components/AddData'
import Icon from './icon/Icon';
import Boundary from './Boundary';
import AOI from './AOI';
import Alur from './Alur';
import MMSI from './Mmsi';
import Distance from './Distance';
import Noimo from './Noimo';
import Aisoff from './Aisoff';
import Doublecallsign from './Doublecallsign';
import Idle from './Idle';
import Search from './Search';
import SearchFleet from './SearchFleet';
import UploadFile from './UploadFile';
import SavedDrawing from './SavedDrawing';

const basemap: { map: string; src: string; }[] = [
	{
	  map: "OSM",
	  src: osm
	},
	{
	  map: "DARK",
	  src: dark
	}
];

const myfleetOption: { option:string; value:string; icon:string; disable:boolean; }[] = [
	{
		option: "AIS",
		value: "fleetrt_fleetrt",
		icon: "Navigation",
		disable: false
	},
	{
		option: "GPS",
		value: "gpsapirt_gpsapirt",
		icon: "GpsFixed",
		disable: false
	},
	{
		option: "IOT Card",
		value: "iot_iot",
		icon: "PushPin",
		disable: true
	}
];


const Header = () => {

	const dispatch = useDispatch();
	const basemapAsli = useSelector((state: RootStateOrAny) => state.basemapAsli);
	const toolsActive = useSelector((state: RootStateOrAny) => state.changeTools);
    const openLegendRight = useSelector((state: RootStateOrAny) => state.openRight);
		const openLegendRightDrawing = useSelector((state: RootStateOrAny) => state.openPanelDrawing);
	
	const { darkModeStatus } = useDarkMode();
	const [layerModalStatus, setLayerModalStatus] = useState<boolean>(false);
	const [alertModalStatus, setAlertModalStatus] = useState<boolean>(false);
	const [activeTab, setActiveTab] = useState<string>('layers');
	const [activeTabAlert, setActiveTabAlert] = useState<string>('boundary');
	const [activeList, setActiveList] = useState<string>('');
	const [layerData, setLayerData] = useState<{
		id: string;
		name: string;
		url: string;
		layer: string;
		legend: string;
		category: string;
	}[]>([])
	const [listData, setListData] = useState<{
		id: string;
		name: string;
		url: string;
		layer: string;
		legend: string;
		category: string;
	}[]>([])

	useEffect(() => {
		if(openLegendRight === true){
			setLayerModalStatus(false)
		}
	}, [openLegendRight]);

	useEffect(() => {
		dispatch({
			type: 'BASEMAPASLI',
			payload: "",
		});
	}, []);

	const handleOpenLayer = () => {
		dispatch({
			type: 'OPENRIGHT',
			payload: false,
		});
		setLayerModalStatus(!layerModalStatus);
	}

	const handleOpenAlert = () => {
		setAlertModalStatus(!alertModalStatus);
	}

	const handleChangeTab = (tab: string) => {
		if (activeTab !== tab) {
		  setActiveTab(tab);
		}
	  };	

	const handleChangeAlert = (tab: string) => {
	if (activeTabAlert !== tab) {
		setActiveTabAlert(tab);
	}
	};

	const handleOpenLegend = () => {
		fetchBookmark()
		.then((response) => {
			let dataBookmark:  any = []
			let data = response.data.results;

			data.map((item:any, index: number) => {
				dataBookmark.push(				
					{
						id: item.id,
						name: item.name,
						url: item.url,
						layer: item.layer,
						type: item.type_service,
						status: "add",
						checkedActive: true,
						checkedLegend: true,
						transparan: 1,
						yindex: index 
					}				
				)
			})

			console.log(dataBookmark, "bkk")

			dispatch({
				type: 'OPENRIGHT',
				payload: true,
			});			
			
			dispatch({
				type: 'LAYERMAP',
				payload: dataBookmark,
			})

			})
		.catch((error) => {
			console.log(error);
		});
	}

	const handleOpenMyFleet = (id: number, name: string, url: string, layer: string, type: string) => {
		const newdata = {
			id: id,
			name: name,
			url: url,
			layer: layer,
			type: type,
			status: "add",
			checkedActive: true,
			checkedLegend: true,
			transparan: 1,
		};

		dispatch({
			type: 'OPENMYFLEET',
			payload: true,
		});			
		
		dispatch({
			type: 'MYFLEET',
			payload: newdata,
		})

		dispatch({
			type: 'FOCUSLAYER',
			payload: {
				status: 'View Draw',
				name: newdata.name
			},
		})
	}
	
	return (
		<>
			<ul className="nav" style={{gap: "14px"}}>
				<li className="nav-item">
					<Dropdown>
						<DropdownToggle>
							<Button color='primary' isLight icon='EmojiTransportation'>
								My Fleets
							</Button>
						</DropdownToggle>
						<DropdownMenu>	
							{myfleetOption.map ((item : any) => (
								<div>
									<DropdownItem isText={item.disable}>
										<div 
											style={{
												flexDirection: 'row', 
												alignItems: 'center',
												justifyContent: 'flex-start',
												gap: '5px',
												alignContent: 'center'
											}}
											onClick = {()=>{	
												dispatch({
													type: 'MYFLEET',
													payload: [],
												})

												dispatch({
													type: 'OPENMYFLEET',
													payload: false,
												});	

												handleOpenMyFleet(1, "MY FLEET" + " " + item.option, `https://wms.redbit.co.id/geoserver/${localStorage.getItem('company_code')}/wms?`, item.value, 'geoserver')
						
											}}
										>
											<Icon icon={item.icon}/>
											<p style={{margin: '0'}}>{item.option}</p>
										</div>
									</DropdownItem>
									<DropdownItem isDivider/>
								</div>
							))}						
						</DropdownMenu>
					</Dropdown>
				</li>
				<li className="nav-item">
					<Button color='primary' isLight icon='Layers' onClick={handleOpenLayer} isDisable={toolsActive !== 'turnOff'}>
						Layer
					</Button>
				</li>
				<li className="nav-item">
					<Dropdown>
						<DropdownToggle>
							<Button color='primary' isLight icon='Public'>
								Base Map
							</Button>
						</DropdownToggle>
						<DropdownMenu>	
							{basemap.map ((item : any) => (
								<div>
									<DropdownItem>
										<div 
											style={{
												flexDirection: 'column', 
												alignItems: 'flex-start'
											}}
											onClick = {(e)=>{
												
												dispatch({
													type: 'BASEMAPASLI',
													payload: item.map,
												});
											}}
										>
											<span>{item.map}</span>
											<img
												style={{
													height: "60px",
													width: "140px",
													display: "block"
												}}
												alt="..."
												src={item.src}
											/>
										</div>
									</DropdownItem>
									<DropdownItem isDivider/>
								</div>
							))}						
						</DropdownMenu>
					</Dropdown>
				</li>
				<li className="nav-item">
					<Dropdown>
						<DropdownToggle>
							<Button color='primary' isLight icon='BorderColor' isDisable={toolsActive !== 'turnOff' || openLegendRightDrawing}>
								Tools
							</Button>
						</DropdownToggle>
						<DropdownMenu>
							<DropdownItem>
								<div 
									style={{
										flexDirection: 'row', 
										alignItems: 'center',
										justifyContent: 'flex-start',
										gap: '5px',
										alignContent: 'center',
										background: toolsActive === 'Point' ? '#7b6dd7' : 'none',
										color: toolsActive === 'Point' ? 'white' : '#808191'
									}}
									onClick = {()=>{
										
										dispatch({
											type: 'TOOLSCHANGE',
											payload: 'Point',
										});
									}}
								>
									<Icon icon='AddLocation'/>
									<p style={{margin: '0'}}>Point</p>
								</div>
							</DropdownItem>
							<DropdownItem isDivider/>
							<DropdownItem>
								<div 
									style={{
										flexDirection: 'row', 
										alignItems: 'center',
										justifyContent: 'flex-start',
										gap: '5px',
										alignContent: 'center',
										background: toolsActive === 'LineString' ? '#7b6dd7' : 'none',
										color: toolsActive === 'LineString' ? 'white' : '#808191'
									}}
									onClick = {()=>{
										
										dispatch({
											type: 'TOOLSCHANGE',
											payload: 'LineString',
										});
									}}
								>
									<Icon icon='AutoGraph'/>
									<p style={{margin: '0'}}>Line</p>
								</div>
							</DropdownItem>
							<DropdownItem isDivider/>
							<DropdownItem>
								<div 
									style={{
										flexDirection: 'row', 
										alignItems: 'center',
										justifyContent: 'flex-start',
										gap: '5px',
										alignContent: 'center',
										background: toolsActive === 'Polygon' ? '#7b6dd7' : 'none',
										color: toolsActive === 'Polygon' ? 'white' : '#808191'
									}}
									onClick = {()=>{
										
										dispatch({
											type: 'TOOLSCHANGE',
											payload: 'Polygon',
										});
									}}
								>
									<Icon icon='Brush'/>
									<p style={{margin: '0'}}>Polygon</p>
								</div>
							</DropdownItem>
							<DropdownItem isDivider/>
							{/* <DropdownItem>
								<div 
									style={{
										flexDirection: 'row', 
										alignItems: 'center',
										justifyContent: 'flex-start',
										gap: '5px',
										alignContent: 'center',
										background: toolsActive === 'Circle' ? '#7b6dd7' : 'none',
										color: toolsActive === 'Circle' ? 'white' : '#808191'
									}}
									onClick = {()=>{
										
										dispatch({
											type: 'TOOLSCHANGE',
											payload: 'Circle',
										});
									}}
								>
									<Icon icon='Adjust'/>
									<p style={{margin: '0'}}>Radius</p>
								</div>
							</DropdownItem>
							<DropdownItem isDivider/> */}
							{/* <DropdownItem>
								<div 
									style={{
										flexDirection: 'row', 
										alignItems: 'center',
										justifyContent: 'flex-start',
										gap: '5px',
										alignContent: 'center',
									}}
									onClick = {()=>{
										
										dispatch({
											type: 'TOOLSCHANGE',
											payload: 'turnOff',
										});
									}}
								>
									<Icon icon='HighlightOff'/>
									<p style={{margin: '0'}}>Turn Off</p>
								</div>
							</DropdownItem> */}
						</DropdownMenu>
					</Dropdown>
				</li>
				<li className="nav-item">
					<Button color='primary' isLight icon='Notifications' onClick={handleOpenAlert} isDisable={toolsActive !== 'turnOff'}>
						Alert
					</Button>
				</li>			
				<li className="nav-item">
					<Button color='primary' isLight icon='Book' onClick={handleOpenLegend} isDisable={toolsActive !== 'turnOff'}>
						Bookmark
					</Button>
				</li>
				<SearchFleet/>
			</ul>
			<OffCanvas
				id='layer'
				isOpen={layerModalStatus}
				setOpen={setLayerModalStatus}
				placement='top'
				isModalStyle
				isBackdrop={false}
				isBodyScroll
				style={{
					top:'70px', 
					left:'200px',
					right: '180px',
					bottom: '45px',
					height: 'auto',
					backgroundColor: darkModeStatus ? 'black' : 'rgb(238, 237, 241, 0.5)'				
				}}
				>
				<OffCanvasHeader setOpen={setLayerModalStatus}>
					<div style={{display: 'flex', gap: '12px'}}>
						<Button
							color='primary'
							icon='Add'
							isLight={activeTab === 'layers' ? false : true}
							onClick={() => handleChangeTab('layers')}
							>
							Add Layers
						</Button>
						<Button
							color='primary'
							icon='Add'
							isLight={activeTab === 'services' ? false : true}
							onClick={() => handleChangeTab('services')}
							>
							Add Services
						</Button>
						<Button
							color='primary'
							icon='Add'
							isLight={activeTab === 'data' ? false : true}
							onClick={() => handleChangeTab('data')}
							>
							Add Raw Data
						</Button>
						{/* <Button
							color='primary'
							icon='Add'
							isLight={activeTab === 'upload' ? false : true}
							onClick={() => handleChangeTab('upload')}
							>
							Upload File
						</Button> */}
						<Button
							color='primary'
							icon='Add'
							isLight={activeTab === 'savedraw' ? false : true}
							onClick={() => handleChangeTab('savedraw')}
							>
							Saved Drawing
						</Button>
					</div>
				</OffCanvasHeader>
				<OffCanvasBody>
					<div hidden={activeTab === 'layers' ? false : true}>
						<AddLayers />
					</div>
					<div hidden={activeTab === 'services' ? false : true}>
						<AddServices />
					</div>
					<div hidden={activeTab === 'data' ? false : true}>
						<AddData />
					</div>
					<div hidden={activeTab === 'upload' ? false : true}>
						<UploadFile />
					</div>					
					<div hidden={activeTab === 'savedraw' ? false : true}>
						<SavedDrawing />
					</div>
				</OffCanvasBody>
			</OffCanvas>

			<OffCanvas
				id='alert'
				isOpen={alertModalStatus}
				setOpen={setAlertModalStatus}
				placement='top'
				isModalStyle
				isBackdrop={false}
				isBodyScroll
				style={{
					top:'70px', 
					left:'200px',
					right: '180px',
					bottom: '45px',
					height: 'auto',
					backgroundColor: darkModeStatus ? 'black' : 'rgb(238, 237, 241, 0.5)'				
				}}
				>
				<OffCanvasHeader setOpen={setAlertModalStatus}>
					<div style={{display: 'flex', gap: '12px'}}>
						<Button
							color='primary'
							// icon='Add'
							isLight={activeTabAlert === 'boundary' ? false : true}
							onClick={() => handleChangeAlert('boundary')}
							>
							Boundary
						</Button>
						<Button
							color='primary'
							// icon='Add'
							isLight={activeTabAlert === 'aoi' ? false : true}
							onClick={() => handleChangeAlert('aoi')}
							>
							AOI
						</Button>
						<Button
							color='primary'
							// icon='Add'
							isLight={activeTabAlert === 'alur' ? false : true}
							onClick={() => handleChangeAlert('alur')}
							>
							Alur
						</Button>
						<Button
							color='primary'
							// icon='Add'
							isLight={activeTabAlert === 'mmsi' ? false : true}
							onClick={() => handleChangeAlert('mmsi')}
							>
							Double MMSI
						</Button>
						<Button
							color='primary'
							// icon='Add'
							isLight={activeTabAlert === 'distance' ? false : true}
							onClick={() => handleChangeAlert('distance')}
							>
							Close Distance
						</Button>
						<Button
							color='primary'
							// icon='Add'
							isLight={activeTabAlert === 'idle' ? false : true}
							onClick={() => handleChangeAlert('idle')}
							>
							IDLE
						</Button>
						<Button
							color='primary'
							// icon='Add'
							isLight={activeTabAlert === 'callsign' ? false : true}
							onClick={() => handleChangeAlert('callsign')}
							>
							Double Callsign
						</Button>
						<Button
							color='primary'
							// icon='Add'
							isLight={activeTabAlert === 'ais' ? false : true}
							onClick={() => handleChangeAlert('ais')}
							>
							AIS Off
						</Button>
						<Button
							color='primary'
							// icon='Add'
							isLight={activeTabAlert === 'imo' ? false : true}
							onClick={() => handleChangeAlert('imo')}
							>
							NO IMO
						</Button>
						
					</div>
				</OffCanvasHeader>
				<OffCanvasBody>
					<div style={{height: '100%'}} hidden={activeTabAlert === 'boundary' ? false : true}>
						<Boundary />
					</div>
					<div style={{height: '100%'}} hidden={activeTabAlert === 'aoi' ? false : true}>
						<AOI />
					</div>
					<div style={{height: '100%'}} hidden={activeTabAlert === 'alur' ? false : true}>
						<Alur />
					</div>					
					<div style={{height: '100%'}} hidden={activeTabAlert === 'mmsi' ? false : true}>
						<MMSI />
					</div>
					<div style={{height: '100%'}} hidden={activeTabAlert === 'distance' ? false : true}>
						<Distance />
					</div>
					<div hidden={activeTabAlert === 'idle' ? false : true}>
						<Idle/>
					</div>
					<div hidden={activeTabAlert === 'callsign' ? false : true}>
						<Doublecallsign/>
					</div>
					<div hidden={activeTabAlert === 'ais' ? false : true}>
						<Aisoff />
					</div>
					<div hidden={activeTabAlert === 'imo' ? false : true}>
						<Noimo />
					</div>
				</OffCanvasBody>
			</OffCanvas>
		</>
	);
};

export default Header;
