import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import Button from './bootstrap/Button';
import useDarkMode from '../hooks/useDarkMode';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from './bootstrap/OffCanvas';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
	CardSubTitle,
	CardFooter,
} from './bootstrap/Card';
import ListGroup, { ListGroupItem } from './bootstrap/ListGroup';import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from './bootstrap/Dropdown';
import Input from './bootstrap/forms/Input';
import FormGroup from './bootstrap/forms/FormGroup';
import Modal, {
	ModalHeader,
	ModalTitle,
	ModalBody,
	ModalFooter
} from './bootstrap/Modal';
import Checks, { ChecksGroup } from './bootstrap/forms/Checks';
import Collapse from './bootstrap/Collapse';
import { fetchLayer, fetchLayerCat } from '../api/layer/index';
import { useSelector, useDispatch, connect, RootStateOrAny } from 'react-redux';

let layerAdd: any = [];
const AddServices = () => {

	const dispatch = useDispatch();
    const layerMap = useSelector((state: RootStateOrAny) => state.layerMap);
	
	const { darkModeStatus } = useDarkMode();	
	const [id, setId] = useState("");
	const [name, setName] = useState("");
	const [url, setUrl] = useState("");
	const [layer, setLayer] = useState("");
	const [legend, setLegend] = useState("");
	const [category, setCategory] = useState("");
	const [type, setType] = useState("");
	const [dataMap, setDataMap] = useState<{
		id: number;
		name:string;
		url: string;
		layer: string;
		type: string;
		status: string;
	}[]>([]);
	const [layerData, setLayerData] = useState<{
		id: number;
		name: string;
		url: string;
		layer: string;
		legend: string;
		category: string;
		type_service: string
	}[]>([])

	useEffect(() => {
		// fetchLayer()
		// .then((response) => {
		// 	setLayerData(response.data.results);
		// 	let listData = _.uniqBy(response.data.results, (obj: any) => obj.category);
		// 	fetchLayerCat(listData[0].category === null ? '' : listData[0].category)
		// 	.then((response) => {
		// 	})
		// 	.catch((error) => {
		// 		console.log(error);
		// 	});
		// })
		// .catch((error) => {
		// 	console.log(error);
		// });

	}, []);

	useEffect(() => {			
		if (typeof layerMap != "undefined" || []){
			if(layerMap.length > 0){
				setDataMap(layerMap[0]);
				 layerAdd = layerMap[0];
			}
		}
	}, [layerMap]);

	const handleOpenLegend = (name: string, url: string, layer: string, type: string) => {
		const newdata = {
			id: Math.floor(Math.random() * (100 - 1 + 1)) + 1,
			name: name,
			url: url,
			layer: layer,
			type: type,
			status: "add",
			checkedActive: true,
			checkedLegend: true,
			transparan: 1,
		};

		let index = _.findIndex(dataMap, _.pick(newdata, "name"));

		if (index !== -1) {
			layerAdd.splice(index, 1, newdata);
		} else {
			layerAdd.push(newdata);
		}

		dispatch({
			type: 'OPENRIGHT',
			payload: true,
		});			
		
		dispatch({
			type: 'LAYERMAP',
			payload: layerAdd,
		})

	}

	
	return (
		<>
			<div className='row' style={{position: 'relative', top: '30px'}}>
				<div className='col-md-12'>								
					<Card>
						<CardHeader>
							<CardLabel>
								<CardTitle>
									Input Service
								</CardTitle>
							</CardLabel>
						</CardHeader>
						<CardBody>
							<div className='row g-4'>
								<div className='col-12'>
									<FormGroup id='name' label='Name'>
										<Input
											onChange={(e: any) => {setName(e.target.value)}}
											value={name}
											placeholder='Example Layer'
										/>
									</FormGroup>
								</div>
								<div className='col-12'>
									<FormGroup id='url' label='Url'>
										<Input
											onChange={(e: any) => {setUrl(e.target.value)}}
											value={url}
											placeholder='https://example.co.id/geoserver/workspace/wms?'
										/>
									</FormGroup>
								</div>
								<div className='col-12'>
									<FormGroup id='layer' label='Layer'>
										<Input
											onChange={(e: any) => {setLayer(e.target.value)}}
											value={layer}
											placeholder='layer name'
										/>
									</FormGroup>
								</div>
								<div className='col-12'>
									<FormGroup id='type' label='Type Of Service'>
										<select className="form-select" id="inputGroupSelect01"
											onChange={(e: any) => {setType(e.target.value)}}
											value={type}								
										>
											<option selected>Choose...</option>
											<option value="geoserver">Geoserver</option>
											<option value="esri">Esri</option>
										</select>
									</FormGroup>
								</div>
							</div>
						</CardBody>				
						<CardFooter borderSize={3} borderColor='primary' className='justify-content-center'>
							<div>
								<Button color='primary' isLink 
									onClick={()=> handleOpenLegend(name, url, layer, type)}
									>
									View on Map
								</Button>
							</div>
						</CardFooter>
					</Card>
				</div>
			</div>
		</>
	);
};

export default AddServices;
