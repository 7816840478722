import React, { FC, useCallback, useContext, useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Input from '../../../components/bootstrap/forms/Input';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Button from '../../../components/bootstrap/Button';
import { fetchEmailFGSearch, fetchProfileSearch, pushProfilePass } from '../../../api/login/index';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import qs from 'query-string';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import classNames from 'classnames';
import Logo from '../../../components/Logo';
import Spinner from '../../../components/bootstrap/Spinner';

const ResetPassword = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [alert, setAlert] = useState('');
	const [authData, setAuthData] = useState<any>({});
	const [isLoading, setIsLoading] = useState(false);
	const [errorStatus, setErrorStatus] = useState<string>('');
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		let dataAuthEncrypted: any = qs.parse(location.search);
		let dataAuthEncryptedString = dataAuthEncrypted.auth
			.replace(/1sda45/g, '+')
			.replace(/32asdag32/g, '=')
			.replace(/pn230S/g, '/');
		if (dataAuthEncryptedString) {
			let bytes = CryptoJS.AES.decrypt(dataAuthEncryptedString, 'testing123');
			let decryptedData = bytes.toString(CryptoJS.enc.Utf8);
			let parsedDecryptedData = JSON.parse(decryptedData);
			console.log(parsedDecryptedData);
			let dateAuthorized = parsedDecryptedData.date;
			let durationHours = moment.duration(moment().diff(dateAuthorized)).asHours();
			if (1 > durationHours) {
				setAuthData(JSON.parse(decryptedData));
			} else {
				navigate('/auth-pages/login');
			}
		} else {
			navigate('/auth-pages/login');
		}
	}, [location]);

	const handleReset = () => {
		setErrorStatus('');
		if (password == confirmPassword) {
			pushProfilePass(authData.id, authData.name, authData.email, confirmPassword)
				.then((response) => {
					navigate('/auth-pages/login');
				})
				.catch((error) => {
					console.log(error);
					setErrorStatus(error.response.data.detail);
				});
			// if (typeof window.location.search.split('auth=')[1] !== 'undefined') {
			// 	const auth = window.location.search.split('auth=')[1];
			// 	fetchEmailFGSearch(auth)
			// 		.then((response) => {
			// 			let emailnya = '';
			// 			if (response.data.results.length > 0) {
			// 				emailnya = response.data.results[0].email;
			// 				fetchProfileSearch(emailnya)
			// 					.then((response2) => {
			// 						console.log(response2);

			// 						pushProfilePass(response2.data.results[0].id, confirmPassword)
			// 							.then((response) => {
			// 								console.log(response);
			// 								navigate('/auth-pages/login');
			// 							})
			// 							.catch((error) => {
			// 								console.log(error);
			// 							});
			// 					})
			// 					.catch((error) => {
			// 						console.log(error);
			// 					});
			// 			}
			// 			console.log(response.data.results.length + '=' + emailnya);
			// 		})
			// 		.catch((error) => {
			// 			console.log(error);
			// 		});
			// }
		}
	};

	return (
		<PageWrapper isProtected={false} title={'Reset Password'} className={'bg-dark'}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='row g-4'>
									<div className='text-center my-5'>
										<Link
											to='/'
											className={classNames(
												'text-decoration-none  fw-bold display-2',
											)}>
											<Logo width={200} />
										</Link>
									</div>
									<div className='text-center h1 fw-bold mt-5'>
										Reset Password
									</div>
									<div className='text-center h4 text-muted mb-5'>
										Fill the form below to reset your password
									</div>
									<div className='col-12'>
										<FormGroup id='password' label='Password'>
											<Input
												type='password'
												onChange={(e: any) => {
													setPassword(e.target.value);
												}}
												value={password}
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<FormGroup id='confirmPassword' label='Confirm Password'>
											<Input
												type='password'
												onChange={(e: any) => {
													setConfirmPassword(e.target.value);
												}}
												value={confirmPassword}
											/>
											<p
												style={{ color: '#ea868f' }}
												hidden={password === confirmPassword}>
												Password didn't match
											</p>
										</FormGroup>
									</div>
									<div className='col-12'>
										<FormGroup id='confirmPassword'>
											<Button
												color='warning'
												className='w-100 py-3'
												isDisable={
													password !== confirmPassword ||
													password.length === 0 ||
													confirmPassword.length === 0
												}
												onClick={handleReset}>
												{isLoading && <Spinner isSmall inButton isGrow />}
												Continue
											</Button>
										</FormGroup>
										<p
											style={{
												color: '#ea868f',
												marginTop: '10px',
											}}
											hidden={!errorStatus}>
											{errorStatus}
										</p>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default ResetPassword;
