import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { useMeasure } from 'react-use';
// @ts-ignore
import { isWebpSupported } from 'react-image-webp/dist/utils';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import Page from '../../../layout/Page/Page';
import Button from '../../../components/bootstrap/Button';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Logo from '../../../components/Logo';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import {
	editOrder,
	getOrder,
	postOrder,
	tokenizationOrder,
	transactionStatus,
} from '../../../api/payment';
import { Buffer } from 'buffer';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import {
	editPass,
	fetchProfile,
	pushProfile,
	pushProfilePass,
	pushProfilePictureUser,
} from '../../../api/login';
import UserImage4 from '../../../assets/img/wanna/wanna4.png';
import config from '../../../config';
import { Link } from 'react-router-dom';

// const ROOT_URL = Config.defurl;
const ROOT_URL = `https://${localStorage.getItem('company_code')}.exabit.co.id/`;

const ProfileUser = () => {
	const [ref, { height }] = useMeasure<HTMLDivElement>();
	const location = useLocation();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [editActive, setEditActive] = useState(false);
	const [nameUser, setNameUser] = useState('');
	const [companyNameUser, setCompanyNameUser] = useState('');
	const [emailUser, setEmailUser] = useState('');
	const [descriptionUser, setDescriptionUser] = useState('');
	const [pictureUser, setPictureUser] = useState('');
	const [birthdayUser, setBirthdayUser] = useState('');
	const [genderUser, setGenderUser] = useState('');
	const [jobUser, setJobUser] = useState('');
	const [loginTypeUser, setLoginTypeUser] = useState('');
	const [picBgUrlUser, setPicBgUrlUser] = useState('');
	const [socialIdUser, setSocialIdUser] = useState('');
	const [userlevelUser, setUserlevelUser] = useState('');
	const [pictureTempUser, setPictureTempUser] = useState<FileList>();
	const [showChangePassword, setShowChangePassword] = useState(false);
	const [password, setPassword] = useState('');
	const [passwordRetype, setPasswordRetype] = useState('');

	useEffect(() => {
		fetchProfile(localStorage.getItem('id'))
			.then((res) => {
				setNameUser(res.data.name);
				setCompanyNameUser(res.data.company_code);
				setEmailUser(res.data.email);
				setDescriptionUser(res.data.description);

				setBirthdayUser(res.data.brithday);
				setGenderUser(res.data.gender);
				setJobUser(res.data.job);
				setLoginTypeUser(res.data.login_type);
				setPicBgUrlUser(res.data.picture_background_url);
				setPictureUser(ROOT_URL + res.data.picture_url);
				setSocialIdUser(res.data.social_id);
				setUserlevelUser(res.data.userlevel);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const handleSaveEdit = async () => {
		await setLoading(true);
		let pictureUrl = '-';
		// if (pictureTempUser) {
		// 	await pushProfilePictureUser(pictureTempUser)
		// 		.then((res) => {
		// 			pictureUrl = res.data.url;
		// 		})
		// 		.catch((err) => {
		// 			console.log(err);
		// 		});
		// }
		await pushProfile(
			localStorage.getItem('id'),
			nameUser,
			genderUser,
			birthdayUser,
			descriptionUser,
			jobUser,
			pictureTempUser ? pictureTempUser : pictureUser,
			companyNameUser,
			emailUser,
			loginTypeUser,
			socialIdUser,
			userlevelUser,
		)
			.then((res) => {
				console.log(res);
				setEditActive(false);
			})
			.catch((err) => {
				console.log(err);
			});
		if (showChangePassword) {
			await editPass(localStorage.getItem('id'), password, nameUser, emailUser)
				.then((res) => {
					console.log(res);
					setEditActive(false);
					setShowChangePassword(false);
				})
				.catch((err) => {
					console.log(err);
				});
		}
		await setLoading(false);
	};

	const handleInputImage = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			setPictureUser(URL.createObjectURL(e.target.files[0]));
			setPictureTempUser(e.target.files);
		}
	};

	return (
		<PageWrapper ref={ref} title='Profile'>
			<Page>
				<div className='display-4 text-center fw-bold mb-4 position-relative'>Profile</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						gap: 16,
					}}>
					<div>
						<div
							className='border border-primary rounded-1'
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: 16,
								background: '#a99cfa',
								padding: 16,
								width: 200,
							}}>
							<Button color='primary' isDisable>
								Profile
							</Button>
							<Link to={'account-management'}>
								<Button
									style={{
										width: '100%',
									}}
									color='primary'>
									Account Management
								</Button>
							</Link>
							<Link to={'billing'}>
								<Button
									style={{
										width: '100%',
									}}
									color='primary'>
									Billing
								</Button>
							</Link>
							{localStorage.getItem('is_superuser') && (
								<Link to={'company'}>
									<Button
										style={{
											width: '100%',
										}}
										color='primary'>
										Company
									</Button>
								</Link>
							)}
						</div>
					</div>
					<div
						className='border border-primary rounded-1'
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: 16,
							background: '#a99cfa',
							padding: 32,
							alignItems: 'center',
							width: '100%',
						}}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								gap: 48,
								width: '100%',
							}}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									gap: 16,
									justifyContent: 'center',
									alignItems: 'center',
								}}>
								<img
									style={{
										borderRadius: '50%',
										width: 200,
										height: 200,
									}}
									src={pictureUser}
									onError={() => setPictureUser(UserImage4)}
								/>
								<Input
									type='file'
									id='signUpCompanyImage'
									onChange={handleInputImage}
									multiple={false}
									hidden={!editActive}
								/>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									gap: 16,
								}}>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										gap: 16,
										alignItems: 'center',
									}}>
									<p
										style={{
											margin: 0,
											fontSize: 24,
											color: '#F9F9FD',
											width: 250,
										}}>
										Name
									</p>
									{editActive ? (
										<FormGroup
											tag={'div'} // 'div' || 'section'
											// isFloating={true}
											// className={String}
											id={'formName'}
											label={'Name'}
											formText={''}
											isHiddenLabel={true}
											size={'lg'}>
											<Input
												value={nameUser}
												onChange={(
													e: React.FormEvent<HTMLInputElement>,
												) => {
													setNameUser(e.currentTarget.value);
												}}
											/>
										</FormGroup>
									) : (
										<p
											style={{
												margin: 0,
												fontSize: 24,
												color: '#F9F9FD',
											}}>
											: {nameUser}
										</p>
									)}
								</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										gap: 16,
										alignItems: 'center',
									}}>
									<p
										style={{
											margin: 0,
											fontSize: 24,
											color: '#F9F9FD',
											width: 250,
										}}>
										Description
									</p>
									{editActive ? (
										<FormGroup
											tag={'div'} // 'div' || 'section'
											// isFloating={true}
											// className={String}
											id={'formDescription'}
											label={'Description'}
											formText={''}
											isHiddenLabel={true}
											size={'lg'}>
											<Input
												value={descriptionUser}
												onChange={(
													e: React.FormEvent<HTMLInputElement>,
												) => {
													setDescriptionUser(e.currentTarget.value);
												}}
											/>
										</FormGroup>
									) : (
										<p
											style={{
												margin: 0,
												fontSize: 24,
												color: '#F9F9FD',
											}}>
											: {descriptionUser}
										</p>
									)}
								</div>
								<Button
									color='warning'
									hidden={!editActive}
									onClick={() => setShowChangePassword(!showChangePassword)}
									style={{
										width: 200,
									}}>
									{showChangePassword
										? 'Cancel Change Password'
										: 'Change Password'}
								</Button>
								<div
									hidden={!showChangePassword}
									style={{
										display: 'flex',
										flexDirection: 'row',
										gap: 16,
										alignItems: 'center',
									}}>
									<p
										style={{
											margin: 0,
											fontSize: 24,
											color: '#F9F9FD',
											width: 250,
										}}>
										Password
									</p>
									{editActive ? (
										<FormGroup
											tag={'div'} // 'div' || 'section'
											// isFloating={true}
											// className={String}
											id={'formPassword'}
											label={'Password'}
											formText={''}
											isHiddenLabel={true}
											size={'lg'}>
											<Input
												value={password}
												type='password'
												onChange={(
													e: React.ChangeEvent<HTMLInputElement>,
												) => {
													setPassword(e.target.value);
												}}
											/>
										</FormGroup>
									) : (
										<p
											style={{
												margin: 0,
												fontSize: 24,
												color: '#F9F9FD',
											}}>
											: *********
										</p>
									)}
								</div>
								{editActive && (
									<div hidden={!showChangePassword}>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												gap: 16,
												alignItems: 'center',
											}}>
											<p
												style={{
													margin: 0,
													fontSize: 24,
													color: '#F9F9FD',
													width: 250,
												}}>
												Re-type Password
											</p>
											<FormGroup
												tag={'div'} // 'div' || 'section'
												// isFloating={true}
												// className={String}
												id={'formPasswordRetype'}
												label={'PasswordRetype'}
												formText={''}
												isHiddenLabel={true}
												size={'lg'}>
												<Input
													value={passwordRetype}
													onChange={(
														e: React.ChangeEvent<HTMLInputElement>,
													) => {
														setPasswordRetype(e.target.value);
													}}
													type='password'
												/>
											</FormGroup>
										</div>
										<div
											style={{
												width: '100%',
												display: 'flex',
												justifyContent: 'end',
												marginTop: '4px',
											}}>
											<small
												hidden={password === passwordRetype}
												style={{
													color: 'red',
													textAlign: 'end',
												}}>
												Password dont match
											</small>
										</div>
									</div>
								)}
							</div>
							{/* <div
								style={{
									display: 'flex',
									flexDirection: 'column',
									gap: 16,
								}}>
								{editActive ? (
									<FormGroup
										tag={'div'} // 'div' || 'section'
										isFloating={true}
										// className={String}
										id={'formName'}
										label={'Name'}
										formText={''}
										isHiddenLabel={false}
										size={'lg'}
										hidden={!editActive}>
										<Input
											value={nameUser}
											onChange={(e: React.FormEvent<HTMLInputElement>) => {
												setNameUser(e.currentTarget.value);
											}}
										/>
									</FormGroup>
								) : (
									<p
										style={{
											margin: 0,
											fontSize: 24,
											color: '#F9F9FD',
										}}
										hidden={editActive}>
										: {nameUser}
									</p>
								)}
								<p
									style={{
										margin: 0,
										fontSize: 24,
										color: '#F9F9FD',
									}}>
									: {companyNameUser}
								</p>
								<p
									style={{
										margin: 0,
										fontSize: 24,
										color: '#F9F9FD',
									}}>
									: {emailUser}
								</p>
								<p
									style={{
										margin: 0,
										fontSize: 24,
										color: '#F9F9FD',
									}}>
									: {descriptionUser}
								</p>
							</div> */}
						</div>
						<div
							style={{
								display: 'flex',
								width: '100%',
								flexDirection: 'row-reverse',
								gap: 16,
							}}>
							{editActive ? (
								<React.Fragment>
									<Button
										color='secondary'
										onClick={() => setEditActive(false)}
										isDisable={loading}>
										{loading ? 'Loading...' : 'Cancel'}
									</Button>
									<Button
										color='primary'
										onClick={() => handleSaveEdit()}
										isDisable={
											loading ||
											(showChangePassword &&
												(password !== passwordRetype || password === ''))
										}>
										{loading ? 'Loading...' : 'Save'}
									</Button>
								</React.Fragment>
							) : (
								<Button color='primary' onClick={() => setEditActive(true)}>
									Edit Profile
								</Button>
							)}
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default ProfileUser;
