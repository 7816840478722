import React from 'react';
import { RouteProps } from 'react-router-dom';
import { dashboardPagesMenu, demoPagesMenu, pageLayoutTypesPagesMenu, paymentPage, userPage } from '../menu';
import DefaultAside from '../pages/_layout/_asides/DefaultAside';

const asides: RouteProps[] = [
	{ path: userPage.login.path, element: null },
	{ path: userPage.signUp.path, element: null },
	{ path: userPage.forgotPassword.path, element: null },
	{ path: userPage.resetPassword.path, element: null },
	{ path: userPage.verification.path, element: null },
	{ path: paymentPage.paymentStatus.path, element: null },
	// { path: dashboardPagesMenu.paidPlan.path, element: null },
	// { path: pageLayoutTypesPagesMenu.blank.path, element: null },
	{ path: '*', element: <DefaultAside /> },
];

export default asides;
