import axios from 'axios';
import Config from '../../config';
const API_KEY = Config.apikey;
// const ROOT_URL = Config.defurl;
const ROOT_URL = `https://${localStorage.getItem('company_code')}.exabit.co.id/`;
import CryptoJS from 'crypto-js';

export function cekAuth() {
	return {
		headers: { Authorization: API_KEY + localStorage.getItem('token') },
	};
}

export function fetchAlert() {
	const url = `${ROOT_URL}alert/alert/`;
	const request = axios.get(url, cekAuth());
	// pushlogging("get " + url);
	return request;
}

export function pushAlert(body) {
	const url = `${ROOT_URL}alert/alert/`;

	const fdata = new FormData();
	// fdata.append('company_code', body.company_code);
	fdata.append('title', body.title);
	fdata.append('description', body.description);
	fdata.append('userlevel', body.userlevel);
	fdata.append('date', body.date);
	const request = axios.post(url, fdata, cekAuth(), {
		onUploadProgress: (progressEvent) => {
			console.log(progressEvent.loaded / progressEvent.total);
		},
	});
	return request;
}

export function updateAlert(body) {
	const url = `${ROOT_URL}alert/alert/${body.id}/`;

	const fdata = new FormData();
	fdata.append('description', body.description);
	fdata.append('userlevel', body.userlevel);
	// fdata.append('company_code', body.company_code);
	fdata.append('date', body.date);
	fdata.append('title', body.title);
	const request = axios.put(url, fdata, cekAuth(), {
		onUploadProgress: (progressEvent) => {
			console.log(progressEvent.loaded / progressEvent.total);
		},
	});
	return request;
}

export function deleteAlert(id) {
	const url = `${ROOT_URL}alert/alert/${id}/`;

	const request = axios.delete(url, cekAuth(), {
		onUploadProgress: (progressEvent) => {
			console.log(progressEvent.loaded / progressEvent.total);
		},
	});
	return request;
}
