import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { useMeasure } from 'react-use';
// @ts-ignore
import { isWebpSupported } from 'react-image-webp/dist/utils';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import Page from '../../../layout/Page/Page';
import Button from '../../../components/bootstrap/Button';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Logo from '../../../components/Logo';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import {
	editOrder,
	getOrder,
	postOrder,
	tokenizationOrder,
	transactionStatus,
} from '../../../api/payment';
import { Buffer } from 'buffer';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import {
	deleteAccount,
	fetchProfile,
	fetchUsers,
	pushAddAcc,
	pushProfile,
	pushProfilePictureUser,
} from '../../../api/login';
import UserImage4 from '../../../assets/img/wanna/wanna4.png';
import config from '../../../config';
import { Link } from 'react-router-dom';
import PaginationButtons, {
	PER_COUNT,
	dataPagination,
} from '../../../components/PaginationButtons';
import useSortableData from '../../../hooks/useSortableData';
import useMinimizeAside from '../../../hooks/useMinimizeAside';
import useDarkMode from '../../../hooks/useDarkMode';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import Select from '../../../components/bootstrap/forms/Select';
import Option from '../../../components/bootstrap/Option';

// const ROOT_URL = Config.defurl;
const ROOT_URL = `https://${localStorage.getItem('company_code')}.exabit.co.id/`;

interface iUsersData {
	brithday: string;
	company_code: string;
	description: string;
	email: string;
	gender: string;
	id: number;
	job: string;
	login_type: string;
	name: string;
	oauth: string;
	otp: string;
	picture_background_url: string;
	picture_url: string;
	social_id: string;
	userlevel: number;
}

const AccountManagement = () => {
	const [ref, { height }] = useMeasure<HTMLDivElement>();
	const { darkModeStatus, themeStatus } = useDarkMode();
	useMinimizeAside();
	const [toggleRightPanel, setToggleRightPanel] = useState(true);
	const location = useLocation();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [openModalNewUser, setOpenModalNewUser] = useState(false);
	const [openModalDeleteUser, setOpenModalDeleteUser] = useState(false);
	const [openModalUpdateUser, setOpenModalUpdateUser] = useState(false);
	const [idUserToBeDelete, setIdUserToBeDelete] = useState(0);
	const [updatedUser, setUpdatedUser] = useState<iUsersData>({
		brithday: '',
		company_code: '',
		description: '',
		email: '',
		gender: '',
		id: 0,
		job: '',
		login_type: '',
		name: '',
		oauth: '',
		otp: '',
		picture_background_url: '',
		picture_url: '',
		social_id: '',
		userlevel: 0,
	});
	const [newUser, setNewUser] = useState({
		email: '',
		name: '',
		password: '',
		company_code: localStorage.getItem('company_code'),
	});
	const [usersData, setUsersData] = useState<{
		count: number;
		data: iUsersData[];
	}>({
		count: 0,
		data: [],
	});
	const [currentPageUsers, setCurrentPageUsers] = useState<number>(1);
	const [perPageUsers, setPerPageUsers] = useState<number>(PER_COUNT['10']);
	var { items, requestSort, getClassNamesFor } = useSortableData(usersData.data);
	const itemsUsers = items;
	const onCurrentPageUsersItems = dataPagination(itemsUsers, currentPageUsers, perPageUsers);

	useEffect(() => {
		handleFetchUser();
	}, []);

	const handleFetchUser = () => {
		fetchUsers(localStorage.getItem('company_code'))
			.then((res) => {
				console.log(res.data.results);
				let users: iUsersData[] = res.data.results;
				setUsersData({
					count: users.length,
					data: users,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const dataTable = (
		name: string,
		categoryTable: {
			columnName: string[];
			stateName: string[];
		},
		dataTable: {
			items: any[];
			currentPage: number;
			perPage: number;
			setPerPage: React.Dispatch<React.SetStateAction<number>>;
			setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
		},
		background: 'success' | 'secondary' | 'primary' | 'warning' | 'info',
	) => {
		return (
			<div
				className={classNames(
					{
						'col-xxl-12': !toggleRightPanel,
					},
					'col-md-12',
					'col-lg-12',
				)}>
				<Card
					className={classNames(`shadow-3d-light`, {
						'text-dark': darkModeStatus,
						[`bg-lo50-${background}`]: darkModeStatus,
						[`bg-l25-${background}`]: !darkModeStatus,
					})}
					isCompact>
					<CardHeader className='bg-transparent'>
						<CardLabel>
							<CardTitle>{name}</CardTitle>
						</CardLabel>
					</CardHeader>
					<CardBody
						style={{
							height: 300,
						}}
						isScrollable>
						<Button
							color='primary'
							style={{
								marginBottom: 16,
							}}
							onClick={() => setOpenModalNewUser(true)}>
							Add Account
						</Button>
						<table className='table table-modern table-hover'>
							<thead>
								<tr>
									{categoryTable.columnName.map((item) => (
										<th scope='col'>{item}</th>
									))}
									<th scope='col'>Update</th>
									<th scope='col'>Delete</th>
								</tr>
							</thead>
							<tbody>
								{dataPagination(
									dataTable.items,
									dataTable.currentPage,
									dataTable.perPage,
								).map((item) => (
									<tr key={item.id}>
										{categoryTable.stateName.map((stateName) => (
											<td>{item[stateName]}</td>
										))}
										<td>
											<Button
												color='secondary'
												isDisable={item.id == localStorage.getItem('id')}
												onClick={() => {
													setOpenModalUpdateUser(true);
													setUpdatedUser(item);
												}}>
												Update
											</Button>
										</td>
										<td>
											<Button
												color='danger'
												isDisable={item.id == localStorage.getItem('id')}
												onClick={() => {
													setOpenModalDeleteUser(true);
													setIdUserToBeDelete(item.id);
												}}>
												Delete
											</Button>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</CardBody>
					<PaginationButtons
						data={dataTable.items}
						label='items'
						setCurrentPage={dataTable.setCurrentPage}
						currentPage={dataTable.currentPage}
						perPage={dataTable.perPage}
						setPerPage={dataTable.setPerPage}
					/>
				</Card>
			</div>
		);
	};

	const handleChangeNewAcc = (value: string, type: 'email' | 'name' | 'password') => {
		if (type === 'email')
			setNewUser({
				...newUser,
				email: value,
			});
		if (type === 'name')
			setNewUser({
				...newUser,
				name: value,
			});
		if (type === 'password')
			setNewUser({
				...newUser,
				password: value,
			});
	};

	const handleChangeEditAcc = (
		value: string,
		type: 'name' | 'company_code' | 'description' | 'email' | 'gender' | 'job' | 'social_id',
	) => {
		setUpdatedUser({
			...updatedUser,
			[type]: value,
		});
	};

	const handleMakeNewAcc = () => {
		pushAddAcc(newUser)
			.then((res) => {
				setOpenModalNewUser(false);
				handleFetchUser();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleEditAcc = () => {
		pushProfile(
			updatedUser.id,
			updatedUser.name,
			updatedUser.gender,
			updatedUser.brithday,
			updatedUser.description,
			updatedUser.job,
			updatedUser.picture_url,
			updatedUser.company_code,
			updatedUser.email,
			updatedUser.login_type,
			updatedUser.social_id,
			updatedUser.userlevel,
		)
			.then((res) => {
				setOpenModalUpdateUser(false);
				handleFetchUser();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleDeleteAcc = () => {
		deleteAccount(idUserToBeDelete)
			.then((res) => {
				setOpenModalDeleteUser(false);
				handleFetchUser();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<PageWrapper ref={ref} title='Profile'>
			<Page>
				<div className='display-4 text-center fw-bold mb-4 position-relative'>
					Account Management
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						gap: 16,
					}}>
					<div>
						<div
							className='border border-primary rounded-1'
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: 16,
								background: '#a99cfa',
								padding: 16,
								width: 200,
							}}>
							<Link to={'/profile'}>
								<Button
									color='primary'
									style={{
										width: '100%',
									}}>
									Profile
								</Button>
							</Link>
							{/* <Link to={'account-management'}> */}
							<Button
								style={{
									width: '100%',
								}}
								isDisable
								color='primary'>
								Account Management
							</Button>
							{/* </Link> */}
							<Link to={'/profile/billing'}>
								<Button
									style={{
										width: '100%',
									}}
									color='primary'>
									Billing
								</Button>
							</Link>
							{localStorage.getItem('is_superuser') && (
								<Link to={'/profile/company'}>
									<Button
										style={{
											width: '100%',
										}}
										color='primary'>
										Company
									</Button>
								</Link>
							)}
						</div>
					</div>
					<div
						style={{
							width: '100%',
						}}>
						{dataTable(
							'Account Management',
							{
								columnName: [
									'Name',
									'Company Code',
									'Description',
									'Email',
									'Gender',
									'Job',
									// 'Social ID',
								],
								stateName: [
									'name',
									'company_code',
									'description',
									'email',
									'gender',
									'job',
									// 'social_id',
								],
							},
							{
								items: itemsUsers,
								currentPage: currentPageUsers,
								perPage: perPageUsers,
								setCurrentPage: setCurrentPageUsers,
								setPerPage: setPerPageUsers,
							},
							'primary',
						)}
					</div>
				</div>
				<Modal isOpen={openModalNewUser} setIsOpen={setOpenModalNewUser} titleId='alert'>
					<ModalHeader>
						<ModalTitle id='alert-title'>Add Account</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: 16,
								width: '100%',
							}}>
							<FormGroup id='newAccountName' isFloating label='Name'>
								<Input
									value={newUser.name}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										handleChangeNewAcc(e.target.value, 'name')
									}
									type='text'
								/>
							</FormGroup>
							<FormGroup id='newAccountEmail' isFloating label='Email'>
								<Input
									value={newUser.email}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										handleChangeNewAcc(e.target.value, 'email')
									}
									type='email'
								/>
							</FormGroup>
							<FormGroup id='newAccountPassword' isFloating label='Password'>
								<Input
									value={newUser.password}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										handleChangeNewAcc(e.target.value, 'password')
									}
									type='password'
								/>
							</FormGroup>
						</div>
					</ModalBody>
					<ModalFooter>
						<div className='row m-0'>
							<div className='col-6 p-3'>
								<Button color='primary' onClick={() => handleMakeNewAcc()}>
									Save
								</Button>
							</div>
							<div className='col-6 p-3'>
								<Button color='danger' onClick={() => setOpenModalNewUser(false)}>
									Close
								</Button>
							</div>
						</div>
					</ModalFooter>
				</Modal>

				<Modal
					isOpen={openModalUpdateUser}
					setIsOpen={setOpenModalUpdateUser}
					titleId='alert'>
					<ModalHeader>
						<ModalTitle id='alert-title'>Edit Account</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: 16,
								width: '100%',
							}}>
							<FormGroup id='editAccountName' isFloating label='Name'>
								<Input
									value={updatedUser.name}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										handleChangeEditAcc(e.target.value, 'name')
									}
									type='text'
								/>
							</FormGroup>
							<FormGroup id='editAccountEmail' isFloating label='Email'>
								<Input
									value={updatedUser.email}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										handleChangeEditAcc(e.target.value, 'email')
									}
									type='email'
								/>
							</FormGroup>
							<FormGroup id='editAccountDescription' isFloating label='Description'>
								<Input
									value={updatedUser.description}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										handleChangeEditAcc(e.target.value, 'description')
									}
									type='text'
								/>
							</FormGroup>
							<FormGroup id='editAccountJob' isFloating label='Job'>
								<Input
									value={updatedUser.job}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										handleChangeEditAcc(e.target.value, 'job')
									}
									type='text'
								/>
							</FormGroup>
							<FormGroup id='editAccountGender' isFloating label='Gender'>
								<Select
									value={updatedUser.gender}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										handleChangeEditAcc(e.target.value, 'gender')
									}
									ariaLabel='Select Gender'>
									<Option value={''} disabled>
										Select Gender
									</Option>
									<Option value={'male'}>Male</Option>
									<Option value={'female'}>Female</Option>
								</Select>
							</FormGroup>
						</div>
					</ModalBody>
					<ModalFooter>
						<div className='row m-0'>
							<div className='col-6 p-3'>
								<Button color='primary' onClick={() => handleEditAcc()}>
									Save
								</Button>
							</div>
							<div className='col-6 p-3'>
								<Button
									color='danger'
									onClick={() => setOpenModalUpdateUser(false)}>
									Close
								</Button>
							</div>
						</div>
					</ModalFooter>
				</Modal>

				<Modal
					isOpen={openModalDeleteUser}
					setIsOpen={setOpenModalDeleteUser}
					titleId='alert'>
					<ModalHeader>
						<ModalTitle id='alert-title'>Delete Account</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<span>
							This action is not reversible, are you sure you want to delete this
							account?
						</span>
					</ModalBody>
					<ModalFooter>
						<div className='row m-0'>
							<div className='col-6 p-3'>
								<Button color='primary' onClick={() => handleDeleteAcc()}>
									Delete
								</Button>
							</div>
							<div className='col-6 p-3'>
								<Button
									color='danger'
									onClick={() => setOpenModalDeleteUser(false)}>
									Close
								</Button>
							</div>
						</div>
					</ModalFooter>
				</Modal>
			</Page>
		</PageWrapper>
	);
};

export default AccountManagement;
