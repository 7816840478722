import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import Button from './bootstrap/Button';
import useDarkMode from '../hooks/useDarkMode';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from './bootstrap/OffCanvas';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
	CardSubTitle,
	CardFooter,
} from './bootstrap/Card';
import ListGroup, { ListGroupItem } from './bootstrap/ListGroup';import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from './bootstrap/Dropdown';
import Input from './bootstrap/forms/Input';
import FormGroup from './bootstrap/forms/FormGroup';
import Modal, {
	ModalHeader,
	ModalTitle,
	ModalBody,
	ModalFooter
} from './bootstrap/Modal';
import Checks, { ChecksGroup } from './bootstrap/forms/Checks';
import Collapse from './bootstrap/Collapse';
import { fetchLayer, fetchLayerCat } from '../api/layer/index';
import { useSelector, useDispatch, connect, RootStateOrAny } from 'react-redux';

let layerAdd: any = [];
let newId: number = 0;

const AddData = () => {

	const dispatch = useDispatch();
    const layerMap = useSelector((state: RootStateOrAny) => state.layerMap);
	
	const { darkModeStatus } = useDarkMode();
	const [openAlert, setOpenAlert] = useState(false);	
	const [id, setId] = useState(0);
	const [name, setName] = useState("");
	const [text, setText] = useState("");
	const [dataMap, setDataMap] = useState<{
		id: number;
		name:string;
		url: string;
		layer: string;
		type: string;
		status: string;
	}[]>([]);
	const [layerData, setLayerData] = useState<{
		id: number;
		name: string;
		url: string;
		layer: string;
		legend: string;
		category: string;
		type_service: string
	}[]>([])

	useEffect(() => {
		// fetchLayer()
		// .then((response) => {
		// 	setLayerData(response.data.results);
		// 	let listData = _.uniqBy(response.data.results, (obj: any) => obj.category);
		// 	fetchLayerCat(listData[0].category === null ? '' : listData[0].category)
		// 	.then((response) => {
		// 	})
		// 	.catch((error) => {
		// 		console.log(error);
		// 	});
		// })
		// .catch((error) => {
		// 	console.log(error);
		// });

	}, []);

	useEffect(() => {			
		if (typeof layerMap != "undefined" || []){
			if(layerMap.length > 0){
				setDataMap(layerMap[0]);
				 layerAdd = layerMap[0];
			}
		}
	}, [layerMap]);

	const isJsonString = (str: string) => {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	}

	const handleOpenLegend = (name: string, url: string) => {		
		if (isJsonString(url) === true){
			const newdata = {
				id: Math.floor(Math.random() * (100 - 1 + 1)) + 1,
				name: name,
				url: url,
				layer: "layer",
				type: "data",
				status: "add",
				checkedActive: true,
				checkedLegend: true,
				transparan: 1,
				yindex: layerAdd.length
			};
	
			let index = _.findIndex(dataMap, _.pick(newdata, "name"));
	
			if (index !== -1) {
				layerAdd.splice(index, 1, newdata);
			} else {
				layerAdd.push(newdata);
			}
	
			dispatch({
				type: 'OPENRIGHT',
				payload: true,
			});			
			
			dispatch({
				type: 'LAYERMAP',
				payload: layerAdd,
			})	
		} else {
			setOpenAlert(!openAlert);
		}

		
	}

	
	return (
		<>
			<div className='row' style={{position: 'relative', top: '20px'}}>
				<div className='col-md-12'>								
					<Card>
						<CardHeader>
							<CardLabel>
								<CardTitle>
									Input Data
								</CardTitle>
							</CardLabel>
						</CardHeader>
						<CardBody>
							<div className='row g-4'>
							<	div className='col-12'>
									<FormGroup id='name' label='Name'>
										<Input
											onChange={(e: any) => {setName(e.target.value)}}
											value={name}
										/>
									</FormGroup>
								</div>
								<div className='col-12'>
									<FormGroup id='text' label='Data (GeoJson)'>
										<textarea className="form-control" style={{height: '270px'}}
											onChange={(e: any) => {setText(e.target.value)}}
											value={text}
										></textarea>
									</FormGroup>
								</div>
							</div>
						</CardBody>				
						<CardFooter borderSize={3} borderColor='primary' className='justify-content-center'>
							<div>
								<Button color='primary' isLink 
									onClick={()=> handleOpenLegend(name, text)}
								>
									View on Map
								</Button>
							</div>
						</CardFooter>
					</Card>
				</div>
			</div>
			<Modal isOpen={openAlert} setIsOpen={setOpenAlert} titleId='alert'>
				<ModalHeader>
					<ModalTitle id="alert-title">
						Alert
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<span>Data is not valid!</span>
				</ModalBody>
				<ModalFooter>
					<div className='row m-0'>
						<div className='col-6 p-3'>
						<Button
							color="danger"
							onClick={() => setOpenAlert(false)}
							>
							Close
						</Button>
						</div>
					</div>					
				</ModalFooter>
			</Modal>
		</>
	);
};

export default AddData;
