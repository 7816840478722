import axios from "axios";
import Config from "../../config";
const API_KEY = Config.apikey;
// const ROOT_URL = Config.defurl;
const ROOT_URL = `https://${localStorage.getItem('company_code')}.exabit.co.id/`;
 
export function cekAuth() {
  return {
    headers: { Authorization: API_KEY + localStorage.getItem("token") }
  };
}

export function fetchSavedDraw() {
  const url = `${ROOT_URL}drawsave/drawsave/`;
  const request = axios.get(url, cekAuth());
  return request;
}

export function fetchSavedCat() {
  const url = `${ROOT_URL}drawsave/drawsaveusefor/`;
  const request = axios.get(url, cekAuth());
  return request;
}

export function fetchSavedList(usefor) {
  const url = `${ROOT_URL}drawsave/drawsave/?search=${usefor}`;
  const request = axios.get(url, cekAuth());
  return request;
}

export function fetchUploadAll() {
  const url = `${ROOT_URL}fileupload/fileupload/`;
  const request = axios.get(url, cekAuth());
  return request;
}

export function deleteFileUpload(id) {
  const url = `${ROOT_URL}fileupload/fileupload/${id}/`;

  const request = axios.delete(url, cekAuth(), {
    onUploadProgress: progressEvent => {
      console.log(progressEvent.loaded / progressEvent.total);
    }
  });
  return request;
}

export function pushUpload(urlfile, name) {
const url = `${ROOT_URL}fileupload/fileupload/`;

const fdata = new FormData();
fdata.append('company_code', localStorage.getItem("company_code"));
fdata.append('urlfile', urlfile);
fdata.append('description', name);
fdata.append('user_id', localStorage.getItem("id"));
fdata.append('dateCreated', moment().format("YYYY-MM-DD"));
const request = axios.post(url, fdata, cekAuth(), {
  onUploadProgress: progressEvent => {
    console.log(progressEvent.loaded / progressEvent.total);
  }
});
return request;
}

export function proccessUpload(fileupload, ext, filename) {
const url = `${ROOT_URL}fileupload/uploadfile/`;

const fdata = new FormData();
fdata.append("fileupload", fileupload);
fdata.append("ext", ext);
fdata.append("filename", filename);

const request = axios.post(url, fdata, cekAuth(), {
  onUploadProgress: progressEvent => {
    console.log(progressEvent.loaded / progressEvent.total);
  }
});

return request;
}

export function proccessReUpload(fileupload, ext, filename) {
  const url = `${ROOT_URL}/fileupload/uploadfilex/`;
  
  const fdata = new FormData();
  fdata.append("fileupload", fileupload);
  fdata.append("ext", ext);
  fdata.append("filename", filename);
  
  const request = axios.post(url, fdata, cekAuth(), {
    onUploadProgress: progressEvent => {
      console.log(progressEvent.loaded / progressEvent.total);
    }
  });
  
  return request;
  }

export function updateReUpload(id, urlfile, name) {
  const url = `${ROOT_URL}fileupload/fileupload/${id}/`;

  const fdata = new FormData();
  fdata.append('company_code', localStorage.getItem("company_code"));
  fdata.append('urlfile', urlfile);
  fdata.append('description', name);
  fdata.append('user_id', localStorage.getItem("id"));
  fdata.append('dateCreated', moment().format("YYYY-MM-DD"));
  const request = axios.put(url, fdata, cekAuth(), {
    onUploadProgress: progressEvent => {
      console.log(progressEvent.loaded / progressEvent.total);
    }
  });

  return request;
}

export function fetchBookmark() {
  let url = `${ROOT_URL}gasparbookmark/gasparbookmark/?search=${localStorage.getItem("id")}`;
  const request = axios.get(url, cekAuth());
  return request;
}

export function pushAddBookmark(dateLayer, name, urlLayer, layer, type, userid) {
  const url = `${ROOT_URL}gasparbookmark/gasparbookmark/`;

  const fdata = new FormData();  
  fdata.append("date_created", dateLayer);
  fdata.append("updated_at", dateLayer);
  fdata.append("name", name);
  fdata.append("url", urlLayer);
  fdata.append("urlpreview", "-");
  fdata.append("layer", layer);
  fdata.append("legend", "-");
  fdata.append('category', "Perhutani");
  fdata.append('type_service', type);
  fdata.append('userid', userid);
  const request = axios.post(url, fdata, cekAuth(), {
    onUploadProgress: progressEvent => {
      console.log(progressEvent.loaded / progressEvent.total);
    }
  });
  return request;
}

export function deleteBookmark(id) {
  const url = `${ROOT_URL}gasparbookmark/gasparbookmark/${id}/`;

  const request = axios.delete(url, cekAuth(), {
    onUploadProgress: progressEvent => {
      console.log(progressEvent.loaded / progressEvent.total);
    }
  });
  return request;
}

export function fetchLayer() {
  let url = `${ROOT_URL}gaspar/gaspar/`;
  const request = axios.get(url, cekAuth());
  return request;
}

export function fetchLayerCat(category) {
  let url = `${ROOT_URL}gaspar/gaspar/?search=${category}`;
  const request = axios.get(url, cekAuth());
  return request;
}

export function fetchCategory() {
  let url = `${ROOT_URL}groupcategory/groupcategory/`;
  const request = axios.get(url, cekAuth());
  return request;
}

export function pushAddLayer(dateLayer, name, urlLayer, layer, category, type) {
  const url = `${ROOT_URL}gaspar/gaspar/`;

  const fdata = new FormData();  
  fdata.append("date_created", dateLayer);
  fdata.append("updated_at", dateLayer);
  fdata.append("name", name);
  fdata.append("url", urlLayer);
  fdata.append("urlpreview", "-");
  fdata.append("layer", layer);
  fdata.append("legend", "-");
  fdata.append('category', category);
  fdata.append('type_service', type);
  const request = axios.post(url, fdata, cekAuth(), {
    onUploadProgress: progressEvent => {
      console.log(progressEvent.loaded / progressEvent.total);
    }
  });
  return request;
}

export function pushAddCategory(name, datatype) {
  const url = `${ROOT_URL}groupcategory/groupcategory/`;

  const fdata = new FormData();  
  fdata.append("name", name);
  fdata.append("datatype", datatype);
  const request = axios.post(url, fdata, cekAuth(), {
    onUploadProgress: progressEvent => {
      console.log(progressEvent.loaded / progressEvent.total);
    }
  });
  return request;
}

export function updateLayer(id, dateLayer, name, urlLayer, layer, category, type) {
  const url = `${ROOT_URL}gaspar/gaspar/${id}/`;

  const fdata = new FormData();
  fdata.append('url', urlLayer);
  fdata.append("urlpreview", "-");
  fdata.append('layer', layer);
  // fdata.append('legend', legend);
  fdata.append('name', name);
  fdata.append('category', category);
  fdata.append('updated_at', dateLayer);
  fdata.append('date_created', dateLayer);
  fdata.append('type_service', type);
  const request = axios.put(url, fdata, cekAuth(), {
    onUploadProgress: progressEvent => {
      console.log(progressEvent.loaded / progressEvent.total);
    }
  });
  return request;
}

export function deleteLayer(id) {
  const url = `${ROOT_URL}gaspar/gaspar/${id}/`;

  const request = axios.delete(url, cekAuth(), {
    onUploadProgress: progressEvent => {
      console.log(progressEvent.loaded / progressEvent.total);
    }
  });
  return request;
}
 