import axios from 'axios';
import Config from '../../config';
const API_KEY = Config.apikey;
const ROOT_URL = Config.defurl;
// const ROOT_URL = `https://${localStorage.getItem('company_code')}.exabit.co.id/`;

export function cekAuth() {
	return {
		headers: { Authorization: API_KEY + localStorage.getItem('token') },
	};
}

export function postOrder(order) {
	let url = `${ROOT_URL}transactionhis/transactionhis/`;
	const request = axios.post(url, order, cekAuth());
	return request;
}

export function getOrder(order_id) {
	let url = `${ROOT_URL}transactionhis/transactionhis/?search=${order_id}`;
	const request = axios.get(url, cekAuth());
	return request;
}

export function editOrder(order) {
	let url = `${ROOT_URL}plan/planexechis/`;
	const fdata = new FormData();
	fdata.append('company_code', order.company_code);
	fdata.append('datetime', order.datetime);
	fdata.append('order_id', order.order_id);
	fdata.append('plan_id', order.plan_id);
	fdata.append('plan_name', order.plan_name);
	fdata.append('price', order.price);
	fdata.append('quantity', order.quantity);
	fdata.append('status', order.status);
	fdata.append('user_id', order.user_id);
	fdata.append('user_limit', order.user_limit);
	fdata.append('data_limit', order.data_limit);
	const request = axios.put(url, fdata, cekAuth());
	return request;
}

export function tokenizationOrder(order_id, product_name, price, customer_details) {
	const fdata = new FormData();
	fdata.append('order_id', order_id);
	fdata.append('product_name', product_name);
	fdata.append('price', price);
	fdata.append('quantity', '1');
	fdata.append('first_name', customer_details.first_name);
	// fdata.append('last_name', customer_details.last_name);
	fdata.append('email', customer_details.email);
	fdata.append('phone', customer_details.phone);
	let url = `${ROOT_URL}plan/midtranstoken/`;
	const request = axios.post(url, fdata, cekAuth());
	return request;
}

export function transactionStatus(order_id) {
	let url = `${ROOT_URL}plan/transstatus/?order_id=${order_id}`;
	const request = axios.get(url, cekAuth());
	return request;
}

export function fetchCompanyProfile(companyCode) {
	let url = `${ROOT_URL}company/company/?search=${companyCode}`;
	const request = axios.get(url, cekAuth());
	return request;
}
