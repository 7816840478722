import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import classNames from 'classnames';
import Button from './bootstrap/Button';
import useDarkMode from '../hooks/useDarkMode';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from './bootstrap/OffCanvas';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
	CardSubTitle,
	CardFooter,
	CardActions
} from './bootstrap/Card';
import Modal, {
	ModalHeader,
	ModalTitle,
	ModalBody,
	ModalFooter
} from './bootstrap/Modal';
import Input from './bootstrap/forms/Input';
import FormGroup from './bootstrap/forms/FormGroup';
import PaginationButtons, {
	dataPagination,
	PER_COUNT,
} from '../components/PaginationButtons';
import useSortableData from '../hooks/useSortableData';
import Icon from '../components/icon/Icon';
import useSelectTable from '../hooks/useSelectTable';
import { fetchUploadAll, deleteFileUpload, pushUpload, proccessUpload, proccessReUpload, updateReUpload } from '../api/layer/index';
import { useSelector, useDispatch, connect, RootStateOrAny } from 'react-redux';

let layerAdd: any = [];
let newId: number = 0;

const UploadFile = () => {

	const dispatch = useDispatch();
    const layerMap = useSelector((state: RootStateOrAny) => state.layerMap);
	
	const { darkModeStatus } = useDarkMode();
	const [openDelete, setOpenDelete] = useState(false);
	const [openReupload, setOpenReupload] = useState(false);	
	const [id, setId] = useState("");
	const [name, setName] = useState("");
	const [fileUpload, setFileUpload] = useState("");
	const [urlFile, setUrlfile] = useState("");
	const [filename, setFilename] = useState("");
	const [fileReUpload, setFileReUpload] = useState("");
	const [tableItems, setTableItems] = useState<{
		id: string;
		user_id: string;
		company_code: string;
		description: string;
		urlfile: string;
		dateCreated: string;
	}[]>([])
	
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(PER_COUNT['10']);

	const { items, requestSort, getClassNamesFor } = useSortableData(tableItems);
	const onCurrentPageItems = dataPagination(items, currentPage, perPage);
	const { selectTable, SelectAllCheck } = useSelectTable(onCurrentPageItems);	

	useEffect(() => {
		getDatatable();
	}, []);

	const getDatatable = () => {
        fetchUploadAll()
        .then(response => {
            if (response != null) {
                setTableItems(response.data.results);
            }
        })
        .catch(error => {
        	console.log(error);
        });
    }

	const handleSubmit = () => {
        pushUpload(urlFile, name)
        .then(response => {
            if (response != null) {
                setName("");
				setFileUpload("");
				setUrlfile("");
                getDatatable();
            }
        })
        .catch(error => {
			console.log(error);
        })
    };

	const handleOpenDelete = (id: string) => {
		setOpenDelete(!openDelete);
		setId(id);
	};

	const handleOpenReupload = (id: string, urlfile: string, name: string) => {		
		setOpenReupload(!openReupload);
		setId(id);
		setFilename(urlfile);
		setName(name);
	};

	const handleDelete = (id: string) => {
        deleteFileUpload(id)
            .then(response => {
            if (response != null) {
				setOpenDelete(!openDelete); 
                getDatatable();
            }
        })
        .catch(error => {
            console.log("tidak berhasil");
        });
        console.log(id);
    }; 

	const handleReupload = () => {
        updateReUpload(id, urlFile, name)
        .then(response => {
            if (response != null) {
                setName("");
				setFileReUpload("");
				setUrlfile("");
                getDatatable();
				setOpenReupload(false);
            }
        })
        .catch(error => {
			console.log(error);
        })
    };

	
	return (
		<>
			<div className='row' style={{position: 'relative', top: '20px'}}>
				<div className='col-md-12'>								
					<Card>
						<CardHeader>
							<CardLabel>
								<CardTitle>
									Upload File
								</CardTitle>
							</CardLabel>
						</CardHeader>
						<CardBody>
							<div className='row g-4'>
							<	div className='col-12'>
									<FormGroup id='name' label='Name'>
										<Input
											onChange={(e: any) => {setName(e.target.value)}}
											value={name}
										/>
									</FormGroup>
								</div>
								<div className='col-12'>
									<FormGroup id='text' label='Upload File'>
										<Input
											onChange={(event: React.ChangeEvent<HTMLInputElement>) =>{
												setFileUpload(event.target.value);
												const { files } = event.target;
    											const selectedFiles = files as FileList;
												const dataUpload = selectedFiles?.[0].name.split(".");
	
												proccessUpload(selectedFiles?.[0], dataUpload[1], dataUpload[0])
												.then(response => {
													setUrlfile(response.data.url);
												})
												.catch(error => {
													console.log(error);
												});
											}}
											type='file'
											value={fileUpload}
										/>
									</FormGroup>
								</div>
							</div>
						</CardBody>				
						<CardFooter borderSize={3} borderColor='primary' className='justify-content-center'>
							<div>
								<Button color='primary' isLink 
									onClick={()=> handleSubmit()}
								>
									Save
								</Button>
							</div>
						</CardFooter>
					</Card>
					<Card stretch data-tour='list'>
						<CardHeader>
							<CardLabel icon='List' iconColor='info'>
								<CardTitle>
									File Upload List{' '}
									<small className='ms-2'>
										Item:{' '}
										{selectTable.values.selectedList.length
											? `${selectTable.values.selectedList.length} / `
											: null}
										{tableItems.length}
									</small>
								</CardTitle>
							</CardLabel>
						</CardHeader>
						<CardBody className='table-responsive' isScrollable>
							<table className='table table-modern table-hover'>
								<thead>
									<tr>
										{/* <th scope='col'>{SelectAllCheck}</th> */}
										<th
											scope='col'
											onClick={() => requestSort('id')}
											className='cursor-pointer text-decoration-underline'>
											#{' '}
											<Icon
												size='lg'
												className={getClassNamesFor('id')}
												icon='FilterList'
											/>
										</th>
										<th scope='col'>Name</th>
										<th scope='col'>User ID</th>
										<th scope='col'>Company Code</th>
										<th scope='col'>URL</th>		
										<td />
									</tr>
								</thead>
								<tbody>
									{dataPagination(items, currentPage, perPage).map((item) => (
										<tr key={item.id}>																
											<td>{item.id}</td>								
											<td>{item.description}</td>							
											<td>{item.user_id}</td>						
											<td>{item.company_code}</td>
											<td>{item.urlfile}</td>
											<td>
												<Button
													isOutline={!darkModeStatus}
													color='dark'
													isLight={darkModeStatus}
													className={classNames('text-nowrap', {
														'border-light': !darkModeStatus,
													})}
													icon='Upload'
													onClick={()=> handleOpenReupload(item.id, item.urlfile, item.description)}
													>
													Reupload
												</Button>
												<Button
													isOutline={!darkModeStatus}
													color='dark'
													isLight={darkModeStatus}
													className={classNames('text-nowrap', {
														'border-light': !darkModeStatus,
													})}
													icon='Delete'
													onClick={() => handleOpenDelete(item.id)}
													>
													Delete
												</Button>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</CardBody>
						<PaginationButtons
							data={tableItems}
							label='items'
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
							perPage={perPage}
							setPerPage={setPerPage}
						/>
					</Card>
					<Modal isOpen={openReupload} setIsOpen={setOpenReupload} titleId='reupload-file'>
						<ModalHeader>
							<ModalTitle id="delete-title">
								Reupload File
							</ModalTitle>
						</ModalHeader>
						<ModalBody>
							<div className='col-12'>
								<FormGroup id='text' label='Reupload File'>
									<Input
										onChange={(event: React.ChangeEvent<HTMLInputElement>) =>{
											setFileReUpload(event.target.value);
											const { files } = event.target;
											const selectedFiles = files as FileList;
											const dataUpload = selectedFiles?.[0].name.split(".");
											const dataname = filename.split("/");
											const namefile = dataname[2].split(".");

											proccessReUpload(selectedFiles?.[0], dataUpload[1], namefile[0])
											.then(response => {
												setUrlfile(response.data.url);
											})
											.catch(error => {
												console.log(error);
											});
										}}
										type='file'
										value={fileReUpload}
									/>
								</FormGroup>
							</div>
						</ModalBody>
						<ModalFooter>
							<div className='row m-0'>
								<div className='col-6 p-3'>
									<Button color='primary'
										onClick={()=> handleReupload()}
									>
										Save
									</Button>
								</div>
								<div className='col-6 p-3'>
									<Button
										color="danger"
										onClick={() => setOpenReupload(false)}
										>
										Cancel
									</Button>
								</div>								
							</div>					
						</ModalFooter>
					</Modal>
					<Modal isOpen={openDelete} setIsOpen={setOpenDelete} titleId='delete-file'>
						<ModalHeader>
							<ModalTitle id="delete-title">
								Delete File
							</ModalTitle>
						</ModalHeader>
						<ModalBody>
							<p>
								Are you sure to delete file with id: {id} ?
							</p>
						</ModalBody>
						<ModalFooter>
							<div className='row m-0'>
								<div className='col-6 p-3'>
								<Button
									color="info"
									onClick={() => setOpenDelete(false)}
									>
									Cancel
								</Button>
								</div>
								<div className='col-6 p-3'>
									<Button
										color="danger"
										onClick={() => handleDelete(id)}
										>
										Delete
									</Button>
								</div>
							</div>					
						</ModalFooter>
					</Modal>
				</div>
			</div>
		</>
	);
};

export default UploadFile;
