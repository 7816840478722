import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { useMeasure } from 'react-use';
// @ts-ignore
import { isWebpSupported } from 'react-image-webp/dist/utils';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import Page from '../../../layout/Page/Page';
import Button from '../../../components/bootstrap/Button';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Logo from '../../../components/Logo';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import {
	editOrder,
	fetchCompanyProfile,
	getOrder,
	postOrder,
	tokenizationOrder,
	transactionStatus,
} from '../../../api/payment';
import { Buffer } from 'buffer';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import { editCompany, fetchProfile, pushProfile, pushProfilePictureUser } from '../../../api/login';
import UserImage4 from '../../../assets/img/wanna/wanna4.png';
import config from '../../../config';
import { Link } from 'react-router-dom';
import Config from '../../../config/index';
import { preventDefault } from 'ol/events/Event';

const API_URL = Config.defurl;
const ROOT_URL = `https://${localStorage.getItem('company_code')}.exabit.co.id/`;

const ProfileCompany = () => {
	const [ref, { height }] = useMeasure<HTMLDivElement>();
	const location = useLocation();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [editActive, setEditActive] = useState(false);
	const [companyData, setCompanyData] = useState<{
		id: number;
		company_name: string;
		company_address: string;
		company_telp: string;
		company_code: string;
		company_email: string;
		company_img: string;
		npwp: string;
		plan_name: string | null;
		plan_id: number;
		last_payment: string | null;
		next_payment: string | null;
	}>({
		id: 0,
		company_name: '',
		company_address: '',
		company_telp: '',
		company_code: '',
		company_email: '',
		company_img: '',
		npwp: '',
		plan_name: null,
		plan_id: 1,
		last_payment: null,
		next_payment: null,
	});
	const [pictureTempUser, setPictureTempUser] = useState<FileList>();

	useEffect(() => {
		fetchCompanyProfile(localStorage.getItem('company_code'))
			.then((res) => {
				if (res.data.results.length !== 0)
					setCompanyData({
						...res.data.results[0],
						company_img: `${API_URL}/${res.data.results[0].company_img}`,
					});
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const handleSaveEdit = async () => {
		await setLoading(true);
		let pictureUrl = companyData.company_img;
		if (pictureTempUser) {
			await pushProfilePictureUser(pictureTempUser)
				.then((res) => {
					pictureUrl = res.data.url;
				})
				.catch((err) => {
					console.log(err);
				});
		}
		await editCompany(companyData, pictureUrl)
			.then((res) => {
				setEditActive(false);
			})
			.catch((err) => {
				console.log(err);
			});
		await setLoading(false);
	};

	const handleInputImage = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		if (e.target.files) {
			setCompanyData((prev) => {
				let temp = Object.assign({}, prev);
				temp['company_img'] = URL.createObjectURL(e.target.files![0]);
				return temp;
			});
			setPictureTempUser(e.target.files);
		}
	};

	const handleEdit = (
		value: string,
		stateName: 'company_name' | 'company_address' | 'company_telp' | 'npwp' | 'company_img',
	) => {
		setCompanyData((prev) => {
			let temp = Object.assign({}, prev);
			temp[stateName] = value;
			return temp;
		});
	};

	return (
		<PageWrapper ref={ref} title='Profile'>
			<Page>
				<div className='display-4 text-center fw-bold mb-4 position-relative'>
					Profile Company
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						gap: 16,
					}}>
					<div>
						<div
							className='border border-primary rounded-1'
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: 16,
								background: '#a99cfa',
								padding: 16,
								width: 200,
							}}>
							<Link to={'/profile'}>
								<Button
									color='primary'
									style={{
										width: '100%',
									}}>
									Profile
								</Button>
							</Link>
							<Link to={'/profile/account-management'}>
								<Button
									style={{
										width: '100%',
									}}
									color='primary'>
									Account Management
								</Button>
							</Link>
							<Link to={'/profile/billing'}>
								<Button
									style={{
										width: '100%',
									}}
									color='primary'>
									Billing
								</Button>
							</Link>
							{/* <Link to={'profile-company'}> */}
							<Button
								isDisable
								style={{
									width: '100%',
								}}
								color='primary'>
								Company
							</Button>
							{/* </Link> */}
						</div>
					</div>
					<div
						className='border border-primary rounded-1'
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: 16,
							background: '#a99cfa',
							padding: 32,
							alignItems: 'center',
							width: '100%',
						}}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								gap: 48,
								width: '100%',
							}}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									gap: 16,
									justifyContent: 'center',
									alignItems: 'center',
								}}>
								<img
									style={{
										borderRadius: '50%',
										width: 200,
										height: 200,
									}}
									src={companyData.company_img ?? UserImage4}
								/>
								<Input
									type='file'
									id='signUpCompanyImage'
									onChange={handleInputImage}
									multiple={false}
									hidden={!editActive}
								/>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									gap: 16,
								}}>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										gap: 16,
										alignItems: 'center',
									}}>
									<p
										style={{
											margin: 0,
											fontSize: 24,
											color: '#F9F9FD',
											width: 250,
										}}>
										Name
									</p>
									{editActive ? (
										<FormGroup
											tag={'div'} // 'div' || 'section'
											// isFloating={true}
											// className={String}
											id={'formName'}
											label={'Name'}
											formText={''}
											isHiddenLabel={true}
											size={'lg'}>
											<Input
												value={companyData.company_name}
												onChange={(
													e: React.ChangeEvent<HTMLInputElement>,
												) => {
													handleEdit(e.target.value, 'company_name');
												}}
											/>
										</FormGroup>
									) : (
										<p
											style={{
												margin: 0,
												fontSize: 24,
												color: '#F9F9FD',
											}}>
											: {companyData.company_name}
										</p>
									)}
								</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										gap: 16,
										alignItems: 'center',
									}}>
									<p
										style={{
											margin: 0,
											fontSize: 24,
											color: '#F9F9FD',
											width: 250,
										}}>
										Address
									</p>
									{editActive ? (
										<FormGroup
											tag={'div'} // 'div' || 'section'
											// isFloating={true}
											// className={String}
											id={'formCompanyName'}
											label={'Company Name'}
											formText={''}
											isHiddenLabel={true}
											size={'lg'}>
											<Input
												value={companyData.company_address}
												onChange={(
													e: React.ChangeEvent<HTMLInputElement>,
												) => {
													handleEdit(e.target.value, 'company_address');
												}}
											/>
										</FormGroup>
									) : (
										<p
											style={{
												margin: 0,
												fontSize: 24,
												color: '#F9F9FD',
											}}>
											: {companyData.company_address}
										</p>
									)}
								</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										gap: 16,
										alignItems: 'center',
									}}>
									<p
										style={{
											margin: 0,
											fontSize: 24,
											color: '#F9F9FD',
											width: 250,
										}}>
										Telephone Number
									</p>
									{editActive ? (
										<FormGroup
											tag={'div'} // 'div' || 'section'
											// isFloating={true}
											// className={String}
											id={'formEmail'}
											label={'Email'}
											formText={''}
											isHiddenLabel={true}
											size={'lg'}>
											<Input
												value={companyData.company_telp}
												onChange={(
													e: React.ChangeEvent<HTMLInputElement>,
												) => {
													handleEdit(e.target.value, 'company_telp');
												}}
											/>
										</FormGroup>
									) : (
										<p
											style={{
												margin: 0,
												fontSize: 24,
												color: '#F9F9FD',
											}}>
											: {companyData.company_telp}
										</p>
									)}
								</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										gap: 16,
										alignItems: 'center',
									}}>
									<p
										style={{
											margin: 0,
											fontSize: 24,
											color: '#F9F9FD',
											width: 250,
										}}>
										NPWP
									</p>
									{editActive ? (
										<FormGroup
											tag={'div'} // 'div' || 'section'
											// isFloating={true}
											// className={String}
											id={'formDescription'}
											label={'Description'}
											formText={''}
											isHiddenLabel={true}
											size={'lg'}>
											<Input
												value={companyData.npwp}
												onChange={(
													e: React.ChangeEvent<HTMLInputElement>,
												) => {
													handleEdit(e.target.value, 'npwp');
												}}
											/>
										</FormGroup>
									) : (
										<p
											style={{
												margin: 0,
												fontSize: 24,
												color: '#F9F9FD',
											}}>
											: {companyData.npwp}
										</p>
									)}
								</div>
							</div>
						</div>
						<div
							style={{
								display: 'flex',
								width: '100%',
								flexDirection: 'row-reverse',
								gap: 16,
							}}>
							{editActive ? (
								<React.Fragment>
									<Button
										color='secondary'
										onClick={() => setEditActive(false)}
										isDisable={loading}>
										{loading ? 'Loading...' : 'Cancel'}
									</Button>
									<Button
										color='primary'
										onClick={() => handleSaveEdit()}
										isDisable={loading}>
										{loading ? 'Loading...' : 'Save'}
									</Button>
								</React.Fragment>
							) : (
								<Button color='primary' onClick={() => setEditActive(true)}>
									Edit Profile
								</Button>
							)}
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default ProfileCompany;
