import React, { SVGProps } from 'react';

const SvgCustomUsa = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 512 512'
		xmlSpace='preserve'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path
			style={{
				fill: '#f5f5f5',
			}}
			d='M0 0H900V600H0z'
		/>
		<path
			style={{
				fill: 'red',
			}}
			d='M0 0H900V300H0z '
		/>
	</svg>
);

export default SvgCustomUsa;
