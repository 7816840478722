import React, { useEffect, useState } from 'react';
import OffCanvas, { OffCanvasBody, OffCanvasHeader } from '../../../components/bootstrap/OffCanvas';
import Chat, { ChatGroup, ChatHeader } from '../../../components/Chat';
import InputGroup from '../../../components/bootstrap/forms/InputGroup';
import Textarea from '../../../components/bootstrap/forms/Textarea';
import Button from '../../../components/bootstrap/Button';
import USERS from '../../../common/data/userDummyData';
import Avatar from '../../../components/Avatar';
import showNotification from '../../../components/extras/showNotification';
import CHATS from '../../../common/data/chatDummyData';
import UserPicture from '../../../assets/img/wanna/wanna4.png';
import UserIcon from '../../../assets/img/wanna/wanna4.webp';
import config from '../../../config';
import { useNavigate } from 'react-router-dom';
import { fetchAlert } from '../../../api/alert';
import { delay } from '@reduxjs/toolkit/dist/utils';

// const ROOT_URL = Config.defurl;
const ROOT_URL = `https://${localStorage.getItem('company_code')}.exabit.co.id/`;

const CommonHeaderChat = () => {
	const [state, setState] = useState<boolean>(false);
	const [msgCount, setMsgCount] = useState<number>(0);
	const [pictureUser, setPictureUser] = useState(ROOT_URL + localStorage.getItem('picture_url'));
	const navigate = useNavigate();

	useEffect(() => {
		fetchAlert()
			.then(async (response) => {
				const setDelayAlert = (title: string, description: string, ind: number) => {
					setTimeout(() => {
						showNotification(
							<span className='d-flex align-items-center'>
								<span>{title}</span>
							</span>,
							<div role='presentation'>
								<p>{description}</p>
							</div>,
						);
					}, 1000 + 10000 * ind);
				};
				for (let index = 0; index < response.data.results.length; index++) {
					const element = response.data.results[index];
					setDelayAlert(element.title, element.description, index);
				}
			})
			.catch((error) => {
				console.log(error);
			});
		// const timeout = setTimeout(() => {
		// 	setMsgCount(3);
		// 	showNotification(
		// 		<span className='d-flex align-items-center'>
		// 			<Avatar
		// 				srcSet={USERS.CHLOE.srcSet}
		// 				src={USERS.CHLOE.src}
		// 				size={36}
		// 				color={USERS.CHLOE.color}
		// 				className='me-3'
		// 			/>
		// 			<span>{USERS.CHLOE.name} sent a message.</span>
		// 		</span>,
		// 		<div onClick={() => setState(!state)} role='presentation'>
		// 			<p>I think it's really starting to shine.</p>
		// 		</div>,
		// 	);
		// }, 3000);
		// return () => {
		// 	clearTimeout(timeout);
		// };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setMsgCount(0);
		console.log(localStorage, 'iteeem');
	}, [state]);

	return (
		<>
			<div
				className='col d-flex align-items-center cursor-pointer'
				onClick={() => navigate('/profile')}
				role='presentation'>
				<div className='me-3'>
					<div className='text-end'>
						<div className='fw-bold fs-6 mb-0' style={{ textTransform: 'capitalize' }}>
							{localStorage.getItem('name')}
						</div>
						{/* <div className='text-muted'>
							<small>{USERS.CHLOE.position}</small>
						</div> */}
					</div>
				</div>
				<div className='position-relative'>
					{/* <Avatar
						srcSet={USERS.CHLOE.srcSet}
						src={USERS.CHLOE.src}
						size={48}
						color={USERS.CHLOE.color}
					/> */}
					<Avatar
						// srcSet={UserIcon}
						src={pictureUser}
						onError={() => setPictureUser(UserIcon)}
						size={48}
						color='primary'
					/>
					{/* {!!msgCount && (
						<span className='position-absolute top-15 start-85 translate-middle badge rounded-pill bg-danger'>
							{msgCount} <span className='visually-hidden'>unread messages</span>
						</span>
					)}
					<span className='position-absolute top-85 start-85 translate-middle badge border border-2 border-light rounded-circle bg-success p-2'>
						<span className='visually-hidden'>Online user</span>
					</span> */}
				</div>
			</div>
			<OffCanvas
				id='chat'
				isOpen={state}
				setOpen={setState}
				placement='end'
				isModalStyle
				isBackdrop={false}
				isBodyScroll>
				<OffCanvasHeader setOpen={setState} className='fs-5'>
					<ChatHeader to={USERS.CHLOE.name} />
				</OffCanvasHeader>
				<OffCanvasBody>
					<Chat>
						{CHATS.CHLOE_VS_JOHN.map((msg) => (
							<ChatGroup
								key={msg.id}
								messages={msg.messages}
								user={msg.user}
								isReply={msg.isReply}
							/>
						))}
					</Chat>
				</OffCanvasBody>
				<div className='chat-send-message p-3'>
					<InputGroup>
						<Textarea />
						<Button color='info' icon='Send'>
							SEND
						</Button>
					</InputGroup>
				</div>
			</OffCanvas>
		</>
	);
};

export default CommonHeaderChat;
